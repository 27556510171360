import React, { useEffect, useState } from 'react'
import { useParams,useNavigate} from "react-router-dom"
import servicesData from "../../data/cities.json"  

const Delhi = () => {
    const navigate = useNavigate()
    const { types } = useParams();
    const [serviceDetails, setServiceDetails] = useState(null);
    if (!serviceDetails) {
        navigate('*');
      }
    useEffect(() => {
        const selectedService = servicesData.find(service => service.types === types);
        setServiceDetails(selectedService);
    }, [types])

    return(
        <>
            <div className="w-full md:py-[70px] max-md:py-[50px]">
                <div className="container mx-auto px-2">
                    <div className='flex max-lg:flex-col justify-between items-center gap-[30px]'>
                        <div className='w-full'>
                            <h1 className="mb-4">{serviceDetails?.title}</h1>
                            <p className="mb-4 text-[20]">{serviceDetails?.desc}</p>
                        </div>
                        <div className="text-center w-full">
                            <img className="w-full" src={serviceDetails?.img} alt={serviceDetails?.altimage}/>
                        </div>
                    </div>
                    <div className='mt-[20px]'>
                        <p>{serviceDetails?.content}</p>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Delhi;