import React, { useRef, useState, useEffect } from "react";
import GoogleMap from 'google-maps-react-markers';
import Markers from '../Markers/Markers';
import ImageIcons from "../../common/ImageIcons";
import "./HomePage.css";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import evcaranimationpic from "../../assets/Images/ev-caranimation-pic.webp";
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios';

const backgroundImagePath = ImageIcons.bannerimage;
const style = {
    backgroundImage: `url('${backgroundImagePath}')`,
};

const Banner = () => {

    const mapRef = useRef(null)
    const [data, setData] = useState([]);
    const location = useLocation();

    useEffect(() => {

        if (location?.state?.name === "ourBranches") {
            // Scroll to the map section when the component mounts
            mapRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location]);


    useEffect(() => {
        const fetchData = async () => {

            try {
                // Make a GET request
                const response = await axios.get(`${process.env.REACT_APP_URL}/user/getlocation`);

                // Filter data where isActive is true
                const filteredData = response.data.data.filter(item => item.isActive === true);

                // Set the filtered data in the state
                setData(filteredData);
            } catch (error) {
                // Set the error in the state
            }
        };

        // Call the fetchData function
        fetchData();
    }, []);

    const onGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map
    }

    // const coordinates = [
    //     { lat: 26.9124, lng: 75.7873, name: 'Jaipur', },
    //     { lat: 21.1458, lng: 79.0882, name: 'Nagpur', },
    //     { lat: 23.0225, lng: 72.5714, name: 'Ahmedabad', },
    // ];

    return (
        <>
            <section className="home-banner-section w-full pb-20" >
                {/* Home Banner Section */}
                <div className="bg-cover w-full bg-center bg-no-repeat relative" style={style}>
                    <div className="container mx-auto">
                        <div className="hero-content-info max-w-[900px] mx-auto xl:pt-40 md:pt-[80px] max-md:pt-[60px] lg:pb-[180px] md:pb-[130px] max-md:pb-[110px] max-md:px-0 text-center">
                            <h1 className="text-[#fff] xl:text-[64px] m-auto md:mb-[20px] max-md:mb-[15px]">Best <span className="text-[#0B7132] bg-[#fff] px-3 font-bold">Electric</span> Vehicle (EV) Charging Station</h1>
                            <p className="text-[#fff] lg:text-[18px] max-lg:text-[16px] md:mb-[30px] max-md:mb-[20px]">It’s your chance to own an EV Charging Station (Without having to manage it)</p>
                            <div className="button-hero-info flex gap-4 justify-center max-md:flex-col">
                                <Link to="https://apps.apple.com/in/app/myev-point/id6475722779" target="blank" > <button className="cursor-pointer bg-white text-[#0B7132] hover:text-[#fff] hover:bg-[#0B7132] font-semibold md:py-3 max-md:py-3 md:px-6 max-md:px-5 rounded-full inline-flex items-center justify-center duration-[400ms,700ms]">
                                    <span>
                                        <FaApple className='md:h-7 max-md:h-6 md:w-7 max-md:w-6 me-1' />
                                    </span>
                                    <span>APP STORE</span>
                                </button>
                                </Link>
                                <Link to="https://play.google.com/store/apps/details?id=com.vpbroadband.evcharging" target="blank" >
                                    <button className="cursor-pointer bg-white text-[#0B7132] hover:text-[#fff] hover:bg-[#0B7132] font-semibold md:py-3 max-md:py-3 md:px-6 max-md:px-5 rounded-full inline-flex items-center justify-center duration-[400ms,700ms]">
                                        <span>
                                            <FaGooglePlay className='md:h-6 max-md:h-5 md:w-6 max-md:w-5 me-1' />
                                        </span>
                                        <span>GOOGLE PLAY</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Home Banner Section */}

                {/* Start Ev Car bg Green Section */}
                <div className="car-bg-green-section bg-[#0B7132] pb-40 px-3">
                    <div className="bg-green-sec-info md:pb-7">
                        <div className="evcar-info text-center relative lg:-mt-[175px] md:-mt-[95px] max-md:-mt-[80px]">
                            <img className="evcar-img-info m-auto max-w-[1080px] w-full" title="Electric Vehicle Car Charging Station" src={evcaranimationpic} alt="EV Car Charging Station" />
                        </div>
                    </div>
                </div>
                {/* End Ev Car bg Green Section */}

                {/* Discover Places Nearby Section */}
                <div className="discover-nearby-section px-3" >
                    <div className="discover-nearby-info" >
                        <div className="container m-auto max-md:!px-0">
                            <div className="grid max-md:grid-cols-1 md:grid-cols-2 md:gap-10 discover-nearby-box max-w-[1140px] rounded-[15px] md:p-[25px] max-md:p-[15px] m-auto items-center bg-[#E7F0F7]" >
                                <div className="discover-content-col max-md:mb-6" id="ourBranches" ref={mapRef} >
                                    <h4 className="relative text-[#0B7132] font-medium mb-1 green-line-heading">CONVENIENT & FAST</h4>
                                    <h2 className="mb-3">Discover Places Nearby</h2>
                                    <p>Charge up your electric vehicle effortlessly with our strategically located charging stations. We've mapped out convenient spots near you, ensuring that wherever your journey takes you, a reliable charging solution is just around the corner.</p>
                                </div>
                                <div className="discover-nearby-col"  >
                                    <div className="discover-nearby-map-info">
                                        <GoogleMap className="mappin-img-info m-auto"
                                            apiKey="AIzaSyCkfOfMsbxXQJDddclN4qd_u6_l19bvpAc"
                                            defaultCenter={{ lat: 30.7333, lng: 76.7794 }}
                                            defaultZoom={7}
                                            mapMinHeight="400px"
                                            onGoogleApiLoaded={onGoogleApiLoaded}
                                            alt="google_Map"

                                        >
                                            {data.map(({ latitude, longitude, name }, index) => (
                                                <Markers
                                                    key={index}
                                                    lat={latitude}
                                                    lng={longitude}
                                                    markerId={name}

                                                />
                                            ))}
                                        </GoogleMap>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Discover Places Nearby Section */}
            </section>
        </>
    )
}

export default Banner;