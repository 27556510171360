import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { windowScroll } from "../../helpers/ScrollToTop";
import "./HomePage.css";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from 'axios';

const Galleryinfo = () => {
  const [gallery, setGallery] = useState([])
  const fetchGallery = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/user/getGallery`);
    const filteredData = response.data.data.filter(item => item.isActive === true);
    const sortedGallery = filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setGallery(sortedGallery)
  }

  useEffect(() => {
    fetchGallery()
  }, [])

  const sliderRef = useRef(null);
  let settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="gallery-section md:pb-20 max-md:pb-[50px] relative">
        <div className="container m-auto">
          <div>
            <h2 className="text-center text-[#000] md:mb-[30px] mb-[20px]">Our Gallery</h2>
          </div>
          <Slider ref={sliderRef} {...settings}>
            {gallery.map((item) => (
              // <Link to={item.readMore} onClick={windowScroll} key={i} className='relative'>
              <div className='relative bg-[#fff] px-[10px] rounded-[10px] mb-[10px] group after:absolute after:content-[""] after:w-full after:h-full  after:origin-left after:opacity-0 after:invisible after:rounded-[10px] after:scale-x-0 after:left-0 after:top-0 after:duration-700 after:hover:opacity-100 after:hover:visible after:hover:scale-x-100 hover:text-white'>
                <div className="relative z-10">
                  <img
                    className="w-full md:h-[350px] max-md:h-[200px] object-cover object-center"
                    src={item.image}
                    alt={item.alt}
                  />
                </div>
              </div>
              // </Link>
            ))}
          </Slider>
          <div className='flex justify-between gap-[15px]'>
              <button type="button" className='h-[40px] w-[40px] bg-[#0B7132] duration-500 hover:bg-[#000] text-white cursor-pointer rounded-[10px] flex justify-center items-center text-[22px]' onClick={() => sliderRef.current.slickPrev()}>
                <IoIosArrowBack />
              </button>
              <button type="button" className='h-[40px] w-[40px] bg-[#0B7132] duration-500 hover:bg-[#000] text-white cursor-pointer rounded-[10px] flex justify-center items-center text-[22px]' onClick={() => sliderRef.current.slickNext()}>
                <IoIosArrowForward />
              </button>
          </div>
          <div className='text-center mt-[10px]'>
            <Link to='/gallery' onClick={windowScroll}><button className="cursor-pointer duration-[400ms,700ms] px-3 min-w-[170px] md:px-3 py-3 md:py-4 bg-[#0B7132]  hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132]  text-white rounded-full">
              Explore More
            </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Galleryinfo;
