import React from "react";
import ImageIcons from '../../common/ImageIcons'

const Shipping = () => {
    return (
        <>
            <div className="w-full md:py-[70px] max-md:py-[50px] md:px-[15px] sm:text-center lg:text-left">
                <div className="container mx-auto lg:flex sm:inline-block items-center">
                    <div className="sm:full lg:w-7/12 ">
                        <h2 className="mb-4">Shipping and Delivery Policy</h2>
                        <p className="mb-4">We, at MyEV point are committed to ensuring that orders are processed and delivered within time and with proper care and efficiency. We ship order with registered domestic courier companies only in 3 ways:</p>
                        <ul className="mb-[20px]">
                            <li><b>Standard Shipping:</b> shipping via standard ground delivery services.</li>
                            <li><b>Expedited:</b> If you require faster delivery.</li>
                            <li><b>Freight:</b> large orders are delivered via freight carriers.</li>
                        </ul>
                        <p>When you place an order with us, the equipment is shipped within 1-2 days and if, in case, the product is not in stock, it will take 2-3 weeks and you will receive it on the promised date.Upon shipment, you will receive an email regarding the same for added convenience. This email will contain a tracking number through which you will be able to track the location of your product. If you have any issues or concerns regarding your order, our customer support team is always there to assist you.</p>
                    </div>
                    <div className="text-center sm:full lg:w-5/12">
                        <img className="h-full w-full" src={ImageIcons?.EVShipping} alt="Shipping" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Shipping;