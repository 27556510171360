import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotFound_gif from "../assets/Images/jrbaV5WNFcCuI5AIJF.webp"
import { windowScroll } from "../helpers/ScrollToTop";

const NotFound = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/')
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <>
            <section className="page_404 bg-[#F1F8E8] pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="text-center mb-10 ">
                                    <img src={NotFound_gif} alt='NotFound_gif' className="mx-auto" />
                                </div>
                                <div className="mt-[-20px]">
                                    <h3 className="text-[40px]">Looks like you're lost!</h3>
                                    <p className="capitalize text-[16px]">the page you are looking for, is not available!</p>
                                    <Link to="/" onClick={windowScroll} className="link_404 text-[#fff] px-[20px] py-[10px] bg-[#0B7132] mx-[20px] inline-block rounded-full my-10">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound;