import React, { useState } from "react";
import "../../../assets/css/adminstyle.css";
import ImageIcons from '../../../common/ImageIcons';
import UserProfile from './Userprofile';
import { Link,useLocation } from "react-router-dom";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CollectionsIcon from '@mui/icons-material/Collections';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const PortalHeader = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;
    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };

    return (
        <div className='dashboard'>
            <div className={isActive ? 'is_active dashboard_left' : 'dashboard_left'}>
                <div className='dashboard_left_content'>
                    <Link className='logo' to='/' alt="logo">
                        <img className='large_logo' src={ImageIcons.myEV_logo} alt="myEV Point Logo" />
                        <img className='small_logo' src={ImageIcons.myEV_logo} alt="myEV Point Logo" />
                    </Link>
                    <div className='dashboard_bar'>
                        <div className='nav'>
                            <ul>
                                {sideBarLinks.map((link, index) => (
                                    <li key={index}><Link to={link.handle}>{link.icon}<span className= {`${pathname === link.handle && "bg-[#0B7132] text-white"} linkText`} >{link.title}</span></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isActive ? "dashboard_right is_active" : "dashboard_right"}>
                <div className='header'>
                    <div className='header_left'>
                        <div className='header_toogle' onClick={handleClick}>
                            <span className="menu_opne">
                                <MenuOpenIcon/>
                            </span>
                            <span className="menu_close">
                                <MenuIcon />
                            </span>
                        </div>
                    </div>
                    <div className='header_right'>
                        <UserProfile />
                    </div>
                </div>
                <div className="p-[30px] mt-[70px]">
                    {children}
                </div>
            </div>
        </div>
    )
}

const sideBarLinks = [
    {
        icon: <AddLocationIcon />,
        title: "Location",
        handle: "/admin-location",
    },
    {
        icon: <NewspaperIcon />,
        title: "Subscribers",
        handle: "/admin-subscriber",
    },
    {
        icon: <ContactPageIcon />,
        title: "Contacts",
        handle: "/admin-contact",
    },
    {
        icon: <PostAddIcon />,
        title: "Blogs",
        handle: "/admin-blog",
    },
    {
        icon: <CollectionsIcon />,
        title: "Gallery",
        handle: "/admin-gallery",
    },

]

export default PortalHeader;