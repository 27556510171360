import React, { useEffect, useState } from "react";
import evCharging from "../../assets/Images/ev_charging_img.webp";
import { Link } from 'react-router-dom'
import { windowScroll } from "../../helpers/ScrollToTop";
import ImageIcons from "../../common/ImageIcons";
import ProgressBar from "@ramonak/react-progress-bar";

const EvCharging = () => {
    const [completed, setCompleted] = useState(0);
    const [completed1, setCompleted1] = useState(0);
    const [completed2, setCompleted2] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCompleted((prevCompleted) => (prevCompleted < 95 ? prevCompleted + 1 : 95));
            setCompleted1((prevCompleted) => (prevCompleted < 80 ? prevCompleted + 1 : 80));
            setCompleted2((prevCompleted) => (prevCompleted < 90 ? prevCompleted + 1 : 90));
        }, 1);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <section className="py-[80px] relative">
                <div className="container mx-auto">
                    <div className="relative flex max-lg:flex-col">
                        <div className="ev-charging_img absolute left-0 right-0 top-[0] h-[100%] w-[100%] lg:w-auto">
                            <img className="lg:max-w-[550px] max-lg:w-[100%] h-[100%] max-lg:h-[450px] rounded-[10px] object-cover object-center" alt="EV Charging" src={evCharging} />
                        </div>
                        <div className="ev-charging_contant w-full relative z-99">
                            <div className="flex justify-between max-lg:flex-col rounded-[10px] bg-[#fff] xl:ml-[362px] lg:ml-[230px] lg:p-[30px] max-lg:p-[15px] max-lg:pb-[0px] lg:mb-[55px] lg:mt-[55px] max-lg:mt-[350px] max-lg:mx-[10px]">
                                <div className="w-[100%] lg:w-[46%] mb-[30px] lg:mb-0">
                                    <h2 className="mb-[20px]">ABOUT My Ev Point</h2>
                                    <p>My EV point, a venture started with an aim of bringing sustainable mobility in India, has created a nationwide network of EV charging stations in order to provide flexibility to users to go freely on long trips on their Electric Vehicle without worrying about the continuously decreasing levels of the battery and the location of next charging stations. We are unwaveringly devoted to accelerating your EV charging experience and provide exceptional services.</p>
                                    <div className="mt-[30px]">
                                        <div className="mb-[15px]">
                                            <label className="text-[16px] text-[#016C80] font-medium mb-[5px] flex">Eco-friendly Charging</label>
                                            <ProgressBar
                                                completed={completed}
                                                bgColor="#016C80"
                                                baseBgColor="#E7F0F7"
                                                height="15px"
                                                width="95%"
                                                labelAlignment="center"
                                                labelSize="12px"
                                                borderRadius="50px"
                                            />
                                        </div>
                                        <div className="mb-[15px]">
                                            <label className="text-[16px] text-[#016C80] font-medium mb-[5px] flex">Cost Effective</label>
                                            <ProgressBar
                                                completed={completed1}
                                                bgColor="#016C80"
                                                baseBgColor="#E7F0F7"
                                                height="15px"
                                                width="95%"
                                                labelAlignment="center"
                                                labelSize="12px"
                                                borderRadius="50px"
                                            />
                                        </div>
                                        <div className="mb-[15px]">
                                            <label className="text-[16px] text-[#016C80] font-medium mb-[5px] flex">EV Drivers Services</label>
                                            <ProgressBar
                                                completed={completed2}
                                                bgColor="#016C80"
                                                baseBgColor="#E7F0F7"
                                                height="15px"
                                                width="95%"
                                                labelAlignment="center"
                                                labelSize="12px"
                                                borderRadius="50px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[100%] lg:w-[54%] pl-0 lg:pl-[50px]">
                                    <div className="flex items-start mb-[30px]">
                                        <img className="sm:w-[75px] max-sm:w-[50px]" src={ImageIcons.icon1} alt="Convenience" />
                                        <div className='lg:pl-[20px] max-lg:pl-[10px] text-left'>
                                            <h4 className='mb-[10px] text-[#016C80] font-semibold'>Convenience</h4>
                                            <p>First of all, our electric vehicle charging stations are located at popular spots and routes means strategic locations have been chosen, as convenient locations pave the way towards hassle free EV charging. Besides this, our services are available round the clock allowing users to make trips whenever they want.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[30px]">
                                        <img className="sm:w-[75px] max-sm:w-[50px]" src={ImageIcons.icon2} alt="Flexibility" />
                                        <div className='lg:pl-[20px] max-lg:pl-[10px] text-left'>
                                            <h4 className='mb-[10px] text-[#016C80] font-semibold'>Flexibility</h4>
                                            <p>Users can pay according to their own preference, as we offer flexible payment methods like payments can be done via RFID cards, mobile applications, debit cards or subscription plans could also be chosen. Also charging levels can be chosen by them according to their own preference.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[40px]">
                                        <img className="sm:w-[75px] max-sm:w-[50px]" src={ImageIcons.icon3} alt="Reliability" />
                                        <div className='lg:pl-[20px] max-lg:pl-[10px] text-left'>
                                            <h4 className='mb-[10px] text-[#016C80] font-semibold'>Reliability</h4>
                                            <p>As electric vehicle charging service providers, we ensure regular monitoring and maintenance of our EV charging stations and remotely located one’s are also not an exception. Issues are being resolved and repaired instantly.</p>
                                        </div>
                                    </div>
                                    <div className='button-getstarted flex max-md:justify-center' onClick={windowScroll}>
                                        <Link to='/contact-us' className="text-white bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] rounded-full md:px-10 max-md:px-8 md:py-4 max-md:py-3 inline-flex items-center duration-[400ms,700ms]">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EvCharging