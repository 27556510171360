import React, { useEffect, useState } from 'react'
import ImageIcons from '../../common/ImageIcons'
import { useParams } from "react-router-dom"
import Chargers from '../servicesDetail/Chargers'
import bgcoverimh1 from "../../assets/Images/bg-cover-imh1.webp";
import chaticon from "../../assets/Images/chat-icon.webp";
import pdficon1 from "../../assets/Images/pdf-icon-1.webp";
import { Link, useLocation } from 'react-router-dom';
import brocher from "../../assets/utils/myEV_borcher.pdf"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../header/header.css"
import servicesData from "../../data/services.json"  

const ServicesDetail = () => {
    const location = useLocation();
    const { pathname } = location;
    const { type } = useParams();
    const [serviceDetails, setServiceDetails] = useState(null);
    useEffect(() => {
        const selectedService = servicesData.find(service => service.type === type);
        setServiceDetails(selectedService);
    }, [type])
    
    const phoneNumber = '+91 95925 95975';
    const phoneLink = `tel:${phoneNumber}`;
    const callPhoneNumber = () => {
        window.location.href = phoneLink;
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };
    const [imageLoaded, setImageLoaded] = useState(false);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <>
            <div className='single-service-page w-full md:py-20 max-md:py-10'>
                <div className='container mx-auto'>
                    <div className='single-services-info flex gap-10 max-md:flex-col'>
                        {/* Left Side Bar Info */}
                        <div className='md:w-1/4 relative mb-5 max-md:order-2'>
                            <div className='single-service-left-sidebar'>
                                {/* Our Services Sidebar Links */}
                                <div className='box-shodow-info xl:px-10 xl:py-10 max-xl:px-5 max-xl:py-5 rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='title-sidebar mb-5'>
                                        <h4>Our Services</h4>
                                        <hr className="green-hr-line" />
                                    </div>
                                    <div className='services-links-info'>
                                        <ul>
                                            <li className={`${pathname === '/services/ac-charging-station' && "bg-[#0B7132] text-white rounded-md"}`}>
                                                <Link to='/services/ac-charging-station' className='flex px-4 py-2 hover:bg-[#0B7132] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]'>AC Charger Service</Link>
                                            </li>
                                            <li className={`${pathname === '/services/dc-charging-station' && "bg-[#0B7132] text-white rounded-md"}`}>
                                                <Link to='/services/dc-charging-station' className='flex px-4 py-2 hover:bg-[#0B7132] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]'>DC Charger Service</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Contact Info Sidebar */}
                                <div className='box-shodow-info rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='contact-sidebar-info'>
                                        <div className='contact-img-gril'>
                                            <img src={bgcoverimh1} alt='electric vehicle customer support' className='w-full' />
                                        </div>
                                        <div className='contact-txt-info relative text-center xl:px-10 xl:pb-10 max-xl:px-5 max-xl:pb-5 mt-[-80px]'>
                                            <div className='icon-contact-info'>
                                                <img src={chaticon} alt='chat_icon' className='w-15 h-15 m-auto' />
                                            </div>
                                            <h4 className='mb-3 mt-4 text-white font-medium'>Do you need any help?</h4>
                                            <p onClick={callPhoneNumber} className='font-medium text-white cursor-pointer'>{phoneNumber}</p>
                                            <p onClick={openMail} className='font-medium text-white cursor-pointer'>{email}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Download Info Sidebar */}
                                <div className='box-shodow-info xl:px-9 xl:py-10 max-xl:px-5 max-xl:py-5 rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='title-sidebar mb-5 pb-5'>
                                        <h4>Download</h4>
                                        <hr className="green-hr-line" />
                                    </div>
                                    <div className='flex flex-row'>
                                        <img src={pdficon1} alt='Pdf' className='w-12 h-12 mr-2' />
                                        <div className='download-txt-info'>
                                            <h5 className='text-lg font-medium'>Download our Brochure</h5>
                                            <p><Link className="flex items-center gap-2 cursor-pointer text-[#0B7132] font-medium" to={brocher} download="myEV_Point"
                                                target="_blank" rel="noopener noreferrer">Download</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Side Bar Info */}
                        <div className='md:w-3/4 relative mb-5 max-md:order-1'>
                            {/* Single Services Content */}
                            <div className='single-service-right-sidebar'>
                                <h2 className='mb-4'>{serviceDetails?.servicetitle}</h2>
                                <div className='flex gap-4 max-md:flex-col'>
                                    <div className='md:w-3/5 mb-3'>
                                        <p className='mb-7' dangerouslySetInnerHTML={{ __html: serviceDetails?.desc1 }}></p>
                                    </div>
                                    <div className='md:w-2/5'>
                                        <div className={`text-center py-4 ${imageLoaded ? '' : 'bg-gray-300 animate-pulse'}`}>
                                            <LazyLoadImage src={serviceDetails?.image} alt='ac electric vehicle charger' className='w-full'
                                                effect="blur"
                                                onLoad={handleImageLoad}
                                                placeholderSrc={ImageIcons.loading} />
                                        </div>
                                    </div>
                                </div>
                                <h3 className='mb-4'>Why Should You Choose Us?</h3>
                                <ul>
                                    {serviceDetails?.additionalInfo.map(info => (
                                        <li key={info.title} className='mb-4'>
                                            <b>{info.title}</b><div dangerouslySetInnerHTML={{ __html: info.description }} />
                                        </li>
                                    ))}
                                </ul>
                                <p>
                                    {serviceDetails?.summary}
                                </p>
                            </div>

                            {/* Chargers Services Content */}
                            <div className='chargers-content-info'>
                                <Chargers />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}



export default ServicesDetail
