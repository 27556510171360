import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AccountContext from '../../../utils/AccountContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import weblogo from "../../../assets/Images/myEV_logo.webp"
import accpic from "../../../assets/Images/EVCharging.webp"

const Login = () => {
  const navigate = useNavigate();
  const { setLogin, setUserData } = useContext(AccountContext);

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === "") {
      alert("Email is required")
    } else if (password === "") {
      alert("Password is required")
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/user/login`, {
          email: email,
          password: password,
        });
        if (response.data) {
          sessionStorage.setItem('userInfo', JSON.stringify(response));
          setLogin(true);
          setUserData(response)
          navigate('/admin-location');
        }
        setEmail('')
        setPassword('')
      } catch (error) {
        alert(error.response.data);
      }
    }
  }

  return (
    <>
    <section className="bg-[#F1F8E8] overflow-y-auto overflow-hidden">
                <div className="container mx-auto h-[100vh] md:!p-[40px] max-md:!p-[20px]">
                    <div className="w-full flex max-w-[1024px] h-full flex-wrap items-center justify-center mx-auto">
                        <div className="w-full">
                            <div className="block rounded-lg bg-white shadow-lg">
                                <div className="g-0 lg:flex lg:flex-wrap">
                                    <div className="px-4 md:px-0 lg:w-6/12">
                                        <div className="md:mx-6 md:p-12">
                                            <div className="lg:mb-[30px] max-lg:mb-[20px] max-lg:pt-[25px]">
                                                <img className="mb-[30px] mx-auto w-[120px]" src={weblogo} alt="myEV Point Logo"  />
                                            </div>
                                            <div className="relative z-9 bg-white mx-auto w-full grid rounded-[10px]">
                                               <form onSubmit={handleSubmit}>
                                                    <div className="relative mb-4">
                                                        <label htmlFor="email" className="mb-[5px] block">Email*</label>
                                                        <input className="w-full h-[50px] border border-[#F1F8E8] bg-[#F1F8E8] px-[15px] rounded-[5px] focus:outline-none focus:border-[#F1F8E8] focus:bg-[#F1F8E8]" name="email"  type="text"  value={email}  placeholder="example@gmail.com"  onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                    <div className="relative mb-4">
                                                        <label htmlFor="password" className="mb-[5px] block">Password*</label>
                                                        <div className="relative">
                                                            <input className="w-full h-[50px] border border-[#F1F8E8] bg-[#F1F8E8] px-[15px] rounded-[5px] focus:outline-none focus:border-[#F1F8E8] focus:bg-[#F1F8E8]" name="password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="********" />
                                                            {showPassword ? (
                                                                <VisibilityOffIcon
                                                                    className="password-toggle-icon absolute right-[10px] top-[12px]"
                                                                    onClick={togglePasswordVisibility}
                                                                />
                                                            ) : (
                                                                <VisibilityIcon
                                                                    className="password-toggle-icon absolute right-[10px] top-[12px]"
                                                                    onClick={togglePasswordVisibility}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="mb-[15px] pt-1 text-center">
                                                        <button className="px-[50px] text-[18px] py-[15px] font-medium text-[#fff] bg-[#0B7132] rounded-[10px] hover:bg-[#000] block w-full" type="Submit">Login</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-e-lg lg:rounded-bl-none max-lg:mb-[50px]">
                                        <div className="relative w-full overflow-hidden">
                                            <img className="mx-auto w-full max-w-[250px]" src={accpic} alt="Acc Icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
};

export default Login;