import React, { useState } from "react";
import ImageIcons from '../../common/ImageIcons';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { windowScroll } from '../../helpers/ScrollToTop';

const ProductSlider = ({ title, description, range, capacity, locations, productSliderImg }) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [zoomedIndex, setZoomedIndex] = useState(null);

    const handleDoubleClick = (index) => {
        if (zoomedIndex === index) {
            setZoomedIndex(null);
        } else {
            setZoomedIndex(index);
        }
    };

    return (
        <>

            <div className="container mx-auto px-2 md:mb-[60px] max-md:mb-[60px] last:mb-0">                <div className="flex gap-8 max-md:flex-col product-slider-info">
                    <div className="md:w-2/5">
                        <div className="prd-slider-info lg:pr-[50px] max-lg:px-[24px] relative">
                            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} arrows={true} fade={false} className="settings lg:ml-[130px]">
                                {productSliderImg.map((item, i) => (
                                    <div className="contact-form-section w-full" key={i}>
                                        <div
                                            className={`relative overflow-hidden cursor-pointer transition-transform duration-300 ${zoomedIndex === i ? "scale-150" : ""
                                                }`}
                                            onClick={() => setZoomedIndex(i === zoomedIndex ? null : i)} // Toggle zoom on click
                                            onDoubleClick={() => handleDoubleClick(i)}
                                        >
                                            <div className="max-w-full flex justify-center">
                                                <img src={item?.productimg} alt={item?.alt} className="object-cover w-full"
                                                    style={{ zIndex: zoomedIndex === i ? 1 : "auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="prd-slider-thumnil-img lg:absolute lg:top-0">
                                <Slider asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={2}
                                    swipeToSlide={true}
                                    arrows={false}
                                    fade={false}
                                    autoplay={false}
                                    autoplaySpeed={5000}
                                    focusOnSelect={true} className="sliderNav">
                                    {productSliderImg.map((item, i) => (
                                        <div key={i}>
                                            <div className="cursor-pointer">
                                                <img src={item.productimg} alt={item?.alt} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-3/5">
                        <h2 className="mb-[15px] h2">{title}</h2>
                        <p className="mb-[15px]">{description}</p>
                        <p><b>Range:</b> {range}</p>
                        <p><b>Capacity:</b> {capacity}</p>
                        <p><b>Locations:</b> {locations}</p>
                        <div className="mt-5">
                            <Link to="/contact-us">
                                <button className="duration-[400ms,700ms] px-3 min-w-[160px] md:px-3 py-3 md:py-4 bg-[#0B7132] bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full" onClick={windowScroll}>
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Product = () => {
    return (
        <div className="contact-form-section w-full py-20">
            <ProductSlider
                title="7.4 kW AC Charging Machine"
                description="MyEV point is preeminent in offering top-quality, cost-effective charging points. Buy our 7.4 kW AC charging stations, which have been designed to meet the varying needs of EV owners in any setting like urban spaces, shopping centres, airports, roadside market areas and other available private areas. Leveraging state-of-the-art technology, our charging points packed with new features offer impeccable convenience and peace of mind. Our customer support services ensure that the needs of customers are addressed and met immediately and efficiently. Wait for what? Choose MyEV point charging stations today and elevate your EV charging experience."
                range="Full car charge in approximately 6 hours."
                capacity="7.4 kW AC"
                locations="Hotels, Residential complexes, Societies, Parking lots, etc."
                productSliderImg={[
                    { productimg: ImageIcons.EVMachine7,alt:"7.4 kW AC charging stations" },
                    { productimg: ImageIcons.MyEVMachine7,alt:"MyEV point charging station" }
                ]}
            />

            <ProductSlider
                title="22 kW AC Charging Machine"
                description="MyEV point’s 22 kW AC charging setup provides high quality charging experience at affordable prices. Our advanced technology of modular power ensures great uptime, as it allows chargers to stay operational even during the events of power module failure, thereby enhancing reliability and convenience. The vehicles can be charged in both cyclic and parallel modes. Buy our 22 kW AC charging stations that can be installed in urban centres, shopping malls, airports or in private locations where EV owners can charge their vehicles promptly and resume their journeys. Our chargers offer unmatched performance and efficiency, making people’s EV driving experience seamless."
                range="Full car charge in approximately 6 hours."
                capacity="22 kW AC"
                locations="Urban centres, shopping malls, airports, private locations"
                productSliderImg={[
                    { productimg: ImageIcons.EVMachine22,alt:"22 kW AC Charging Machine" },
                    { productimg: ImageIcons.MyEVMachine22,alt:"AC charging stations" }
                ]}
            />

            <ProductSlider
                title="30 kW DC Fast Charging Machine"
                description="Buy our 30 kW DC fast charging points and ensure uninterrupted availability of charging for EV owners, as our cutting-edge modular power guarantees maximum uptime even during the very rare events of power failure. Offering state-of-the-art technological features allow our charging stations to cater to varying requirements of charging and preferences of users. Our charging stations offer phenomenal performance in every setting be it city centres, shopping malls, office spaces, hospitals or highway restaurants by providing swift charging solutions."
                range="Full car charge in approximately 45 Min to 1 hour."
                capacity="30 kW DC fast charging."
                locations="Highway Restaurants, Dhaba, Malls, Hospitals etcetera."
                productSliderImg={[
                    { productimg: ImageIcons.EVMachine30,alt:"30 kW DC Fast Charging Setup" },
                    { productimg: ImageIcons.MyEVMachine30,alt:"DC fast charging points" }
                ]}
            />

            <ProductSlider
                title="60 kW DC (Fast) Charging Machine"
                description="MyEV point’s 60 kW DC charging point is the epitome of swift and reliable EV charging. State-of-the-art technology ensures lightning-fast charging times without any compromises with safety and efficiency. These charging set ups allow EV owners to resume their journeys swiftly by reduced charging times. Buy our set up which caters to diverse needs and provides quick top-ups for daily commute and fast charging solutions for your long journey so that you do not have any anxiety related to your battery levels. All in all, these are the ultimate solutions for electric vehicles."
                range="Full car charge in approximately 45 Min to 1 Hour."
                capacity="60 kW DC fast charging/ Two Guns."
                locations="Highway Restaurants, Dhaba, Malls, Hospitals, etc."
                productSliderImg={[
                    { productimg: ImageIcons.EVMachine60,alt:"60 kW DC (Fast) Charging Machine" },
                    { productimg: ImageIcons.MyEVMachine60,alt:"MyEV point’s 60 kW DC charging point" }
                ]}
            />
        </div >
    );
};


export default Product;



