import React, { useState } from 'react';
import { Call, Email, WhatsApp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { RiArrowLeftSFill } from "react-icons/ri";

const FloatingActions = () => {
       const [showAction, setShowAction] = useState(
        sessionStorage.getItem('showAction') === 'true' ? true : false
    );
    const handleFloatingAction = () => {
        const newState = !showAction;
        setShowAction(newState);
        sessionStorage.setItem('showAction', newState);
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };

    return (
        <div className='flex flex-row fixed right-[0px] top-[50%] shadow-box z-[1111] transition-[0.8s] ease-in' style={showAction ? { right: '-44px' } : {}}>
            <div className={`cursor-pointer md:h-[44px] max-md:h-[35px] md:w-[30px] max-md:w-[25px] rounded-l-[50px] text-[30px] flex justify-center items-center bg-[#016C80] shadow-box ${showAction ? 'animate-bounce' : ''}`}
                onClick={handleFloatingAction}>
                <RiArrowLeftSFill className='text-white'/>
            </div>
            <div>
                <Link onClick={openMail} className='bg-[#0B7132] md:h-[44px] max-md:h-[35px] md:w-[44px] max-md:w-[35px] flex justify-center items-center' aria-label='Contact Us'>
                    <Email fontSize='medium' htmlColor='#fff' />
                </Link>
                <Link to="tel:+919592595975" className='bg-[#016C80] md:h-[44px] max-md:h-[35px] md:w-[44px] max-md:w-[35px] flex justify-center items-center' aria-label='Mobile Number'>
                    <Call fontSize='medium' className='text-white' />
                </Link>
                <Link to="https://wa.me/9592595975" target="_blank" className='bg-[#26D367] md:h-[44px] max-md:h-[35px] md:w-[44px] max-md:w-[35px] flex justify-center items-center' aria-label='WhatsApp'>
                    <WhatsApp fontSize='medium' className='text-white' />
                </Link>
            </div>
        </div>
    )
}

export default FloatingActions;
