import React, { useState } from 'react';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageIcons from '../../common/ImageIcons';
import servicesData from "../../data/services.json"
import { windowScroll } from '../../helpers/ScrollToTop';

const ServicesList = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    return (
        <>
            {/* Services List Section */}
            <div className="services-list-section w-full md:py-20 max-md:py-10">
                <div className="container mx-auto px-2">
                    <div className="flex lg:gap-8 max-lg:gap-5 max-md:flex-col max-w-[992px] m-auto mb-5">
                        {servicesData.map((item, i) => (
                            <div key={i} className="md:w-1/2 relative mb-5">
                                <div className="services-list-info">
                                    <div className={`service-img-info text-center rounded-t-[35px] ${imageLoaded ? '' : 'bg-gray-300 animate-pulse'} `}>
                                        <LazyLoadImage src={item?.image} alt={item?.alt} className="w-full rounded-t-[35px] rounded-lg cursor-pointer object-center object-cover h-[300px]"
                                            effect="blur"
                                            onLoad={handleImageLoad}
                                            placeholderSrc={ImageIcons.loading} />
                                    </div>
                                    <div className='services-list-content bg-[#F8F8F8] xl:px-10 max-xl:px-3 pb-5 rounded-b-[35px]'>
                                        <div className='rounded-xl p-2 bg-[#fff] relative mb-[20px] md:mt-[-25px] max-md:mt-[-20px] shadow-lg'>
                                            <Link to={`/services/${item?.type}`} onClick={windowScroll}><h3 className="text-center text-[#0B7132]">{item?.title}</h3></Link>
                                        </div>
                                        <p className="text-center mb-[20px]" style={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            WebkitLineClamp: 2
                                        }}>
                                            {item?.desc1}
                                        </p>
                                        <div className='btn-readmore-info pb-3'>
                                            <Link to={`/services/${item?.type}`} onClick={windowScroll} className='flex flex-row justify-center items-center font-medium hover:text-[#0B7132]'>
                                                Read More
                                                <FaArrowRightLong className='w-6 h-5 ml-1' />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Services List Section */}
        </>
    )
}

export default ServicesList;
