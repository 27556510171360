import React from "react";
import { FaCarSide, FaChargingStation, FaChartArea, FaFunnelDollar, FaHandshake, FaRecycle  } from "react-icons/fa";
import Counting from "../../components/counting/Counting";
import Images from "../images/Images";

const Franchisee = () => {
    return(
        <>
            <div className="w-full pt-20 pb-5">
                <div className="container mx-auto px-2">
                    <h2 class="text-center md:pb-12 max-md:pb-8 text-[#000]">Start Your EV Charging Business</h2>
                    <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col mb-10">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaCarSide className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Rapid Growth Potential</h4>
                                    <p className="text-sm">Derive the benefit of increasing EV usage by investing in EV charging station franchise and establish a profitable business.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaChargingStation className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Revenue Generation</h4>
                                    <p className="text-sm">Diversify your income sources via charging fees, advertising opportunities, and partnering with the local businesses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaChartArea className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Low Maintenance Cost</h4>
                                    <p className="text-sm">Minimal movie parts require minimum servicing, resulting in low cost of maintenance. All thanks to durable and reliable infrastructure.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col md:mb-5">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaFunnelDollar className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Tax Benefits</h4>
                                    <p className="text-sm">Reduce your tax liabilities by reaping benefits of various tax benefits and incentives offered by governments and local authorities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaHandshake className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Environmentally Friendly</h4>
                                    <p className="text-sm">Contribute towards reducing greenhouse gas emissions and promoting sustainability by providing EV charging solutions for EV owners.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaRecycle className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Return on Investment (ROI)</h4>
                                    <p className="text-sm">Ensure profitability and sustainability with impeccable Return on Investment in the burgeoning electric vehicle market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-10">
                <div className="container mx-auto px-2">
                    <Counting />
                </div>
            </div>
            <div className="w-full">
                <div className="container !max-w-[900px] mx-auto px-2">
                    <h3 class="mb-[15px] text-center">Empower Sustainable Transportation: Pave the Way for a Greener Tomorrow</h3>
                    <p className="mb-[15px] text-center">Without a iota of doubt, to reduce carbon footprint and to combat climate change, it is necessary to look for sustainable transportation solutions and in this quest, embracing the EVs along with investing in EV charging infrastructure seems to be one of the greatest steps towards achieving the goal.</p>
                    <p className="mb-[15px] text-center">It has also been predicted that as the usage of electric vehicles is growing, EV market will see a significant boost in the future. This represents huge opportunities for businesses to invest into a rapidly growing market and secure a profitable future because of potential for significant returns on investment (ROI). Moreover, as the demand for EV charging service is rising, especially in urban areas and along major transportation highways, owners can reap huge financial benefits from their investment.</p>
                    <p className="text-center">Moreover, investing in EV charging infrastructure goes along with sustainability initiatives. Therefore, by supporting electric transportation by providing charging infrastructure, station owners can establish themselves as leaders in sustainability and contribute in efforts towards reducing greenhouse gas emission and combating climatic shifts.</p>
                </div>
            </div>
            <Images/>
            
        </>
    )

}
export default Franchisee;