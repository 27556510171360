import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'count', headerName: 'S.No.', width: 400, },
    { field: 'email', headerName: 'Email', width: 800 },
];

const Subscriber = () => {
    const [subscribers, setSubscribers] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/user/getSubscribersList`);
                const subscribersWithCount = response.data.data.map((subscriber, index) => ({
                    ...subscriber,
                    count: index + 1,
                }));
                setSubscribers(subscribersWithCount);
            } 
                catch (error) {
            }
        };
        fetchData();
    }, []);

    const getRowId = (row) => row._id;
    return (
            <div>
                <div style={{ height: 400, width: '80%',}}>
                <h1 className="uppercase">Subscribers</h1>
                    <DataGrid
                        rows={subscribers}
                        columns={columns}
                        getRowId={getRowId}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    />
                </div>
            </div>
    )
}

export default Subscriber;