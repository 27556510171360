import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ContactInfo from '../components/contact_info/ContactInfo';
import SingleBlogPage from '../components/blog/SingleBlog';
import SingleBlogBanner from "../components/blog/SingleBlogBanner";
import axios from 'axios';
import SEO from '../common/SEO';

const SingleBlogDetail = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { type } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
      const blogsData = response.data.data;
      const foundBlog = blogsData.find(blog => pathname.toLowerCase() === `/blogs/${blog.handle}`);
      if (!foundBlog) {
        navigate('*');
      }
      const selectedBlog = blogsData.filter(index => index.handle === foundBlog?.handle);
      setBlogDetails(selectedBlog[0]);
    } catch (error) {
      // Handle error as needed
    }
  };
  const dateObject = new Date(blogDetails?.publishedAt);
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).replace(/\//g, '-');
  useEffect(() => {
    fetchBlogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, type])

  return (
    <>
      <SEO
        title={blogDetails?.metaTitle}
        description={blogDetails?.metaDescription}
        keywords={blogDetails?.keyword}
        schema={`{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.myevpoint.in/blogs/${blogDetails?.handle}"
          },
          "headline": "${blogDetails?.metaTitle}",
          "image": [
            "${blogDetails?.image}"
          ],
          "datePublished": "${formattedDate}",
          "author": {
            "@type": "Person",
            "name": "${blogDetails?.author}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "My Ev Point",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.myevpoint.in/static/media/myEV_logo.9baa1dd0c29762718e22.webp" 
            }
          },
          "description": "${blogDetails?.metaDescription}"
        }`}
      />

      <Header />
      <SingleBlogBanner blogDetails={blogDetails} />
      <SingleBlogPage blogDetails={blogDetails} />
      <ContactInfo />
      <Footer />
    </>
  )
}

export default SingleBlogDetail;
