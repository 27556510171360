import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from 'axios';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageIcons from '../../common/ImageIcons';
const Images = () => {
  const [open, setOpen] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchGallery = async () => {
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/getGallery`);
      const filteredData = response.data.data.filter(item => item.isActive === true);
      const sortedGallery = filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setGallery(sortedGallery);
      setVisibleItems(sortedGallery.slice(0, 10)); // Initially display the first 8 items
    };

    fetchGallery();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 


  const gotoNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 < gallery.length ? prevIndex + 1 : 0
    );
  };

  return (
    <>
      {/* Gallery Page */}
      <div className="gallery-section md:py-20 max-md:py-[50px] relative">
        <div className="container mx-auto px-2">
          <div className="flex gap-2 ">
            <ul className="gallery-info1 flex gap-3 justify-center flex-wrap pb-2 mx-auto ">
              {visibleItems.map((item, index) => (
                <li key={index} className={`max-sm:w-[45%] ${imageLoaded ? '' : 'bg-gray-300 animate-pulse'}`}>
                  <LazyLoadImage
                    className="cursor-pointer object-center object-cover w-[250px] sm:h-[250px] max-sm:h-[160px]"
                    effect="blur"
                    onLoad={handleImageLoad}
                    placeholderSrc={ImageIcons.loading}
                    src={item?.image}
                    alt={item?.alt}
                    onClick={() => openLightbox(visibleItems.indexOf(item))}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(7px)" }}
      >
        <div>
          <div className="absolute md:left-5 max-md:left-2 top-1/2">
            <ChevronLeftIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoNext} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
          <Box sx={style} className="focus:outline-none focus-visible:outline-none max-md:w-[75%]">
            <div className='flex flex-row items-center bg-[#fff] p-[8px]'>
              <img className='w-full md:h-full md:object-contain md:max-h-[90vh]'
                src={gallery[currentImageIndex]?.image}
                alt={`gallery-${currentImageIndex}`}
              />
            </div>
          </Box>
          <div className="absolute md:right-5 max-md:right-2 top-1/2">
            <ChevronRightIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoNext} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

export default Images;