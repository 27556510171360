import React from "react";
import Header from '../components/header/Header'
import Footer from "../components/footer/Footer";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/EvChargingStationApp.webp";
import MobileAppScreen from "../components/mobile/MobileApp";
import SEO from "../common/SEO";

const MobileApp = () => {
    return (
        <>
            <SEO title='Best Electric Car Charging Station App | MyEVPoint' description='Best apps for EV charging stations, electric car charging, and more. Simplify your EV charging experience with our recommended apps.' 
            keywords="electric car charging station app, ev charging app, ev charging stations app, electric car charging app, app for ev charging stations, app for charging stations, electric charging stations app, electric vehicle charging app, ev charging station application, app for electric car charging, car charging station app, ev station app, electric vehicle charging stations app, ev chargers app"/>
            <Header />
            <InnerPageBanner title='Ev Charging Station App' maxWidth='472px' innerPageBanner={aboutBanner} />
            <MobileAppScreen />
            <Footer />
        </>

    )
};

export default MobileApp;