import React, { useState } from 'react';
import Header from '../components/header/Header';
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/FAQAboutEVChargingStation.webp";
import Footer from '../components/footer/Footer';
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import SEO from '../common/SEO';

const Faq = () => {
    const [expanded, setExpanded] = useState(0);
    function toggleHandler(i) {
        if (expanded === i) {
            setExpanded(null);
        } else {
            setExpanded(i);
        }
    }
    return (
        <>
            <SEO
                title="Electric Vehicle FAQ: Charging Stations, DC & AC Charging"
                description="Study FAQs on electric vehicles, charging stations, DC & AC charging, and fast charging. Get answers to common queries about EVs and charging."
                keywords='electric vehicle,electric cars, electric vehicle charging station.DC charging,AC charging,DC fast charging'
                schema={`{
        {
        "@context": "https://schema.org/", 
        "@type": "FAQPage", 
        "mainEntity": [{
          "@type": "Question", 
          "name": "What is an electric vehicle (EV) and how does it work?",
          "acceptedAnswer": { 
            "@type": "Answer",
            "text": "An electric vehicle is a mode of transportation which uses electricity to power up rather than using fossil fuels like petrol and diesel. These vehicles are equipped with batteries that are charged with the help of electricity and provide power to the motor in the vehicle."
          } 
        },{
          "@type": "Question", 
          "name": "How environmentally friendly are electric vehicles? ",
          "acceptedAnswer": { 
            "@type": "Answer",
            "text": "Electric vehicles come without tailpipes because they do not run on gasoline. So, they do not produce any harmful emissions. Moreover, the environmental effect can further be reduced by using renewable forms of generating electricity."
          }  
        
},{
"@type": "Question",
"name": "What are the benefits of driving an electric car?",
"acceptedAnswer": {
"@type": "Answer",
"text": "The benefits of driving electric cars are abundant. Firstly, they are environmentally friendly solutions, as they do not emit gases like carbon monoxide and hydrocarbons which are produced by traditional vehicles. Also, renewable forms of generating electricity like solar and wind power can be used which further reduce the environmental impact and prove to be cost effective.  They have lower running and maintenance costs. Tax and other financial benefits are offered by governments to EV owners. Can be charged at home."
}

},{
"@type": "Question",
"name": "Can I charge my car on any charger?",
"acceptedAnswer": {
"@type": "Answer",
"text": "It is important to check the compatibility of vehicles with different types of charger. Also, modern electric vehicles come up with a connector or an adapter which allow them to be charged at any station."
}

},{
"@type": "Question",
"name": "Which type of charging station is better for electric cars?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Although all types of electric vehicle charging stations are suitable for EVs, it depends on the driving habits and the needs of the drivers. If a person has to use it for a short distance, then level 1 is also suitable and if one wants faster charging, then one can go for level 2 also. Sometimes people want quick top-ups, so that time, people can choose DC fast charging for their EVs. "
}

},{
"@type": "Question",
"name": "How many types of EV charging stations are available at myEVPoint? ",
"acceptedAnswer": {
"@type": "Answer",
"text": "My EV point offers both AC and DC charging stations for its users."
}

},{
"@type": "Question",
"name": "How can I find nearby electric vehicle charging stations? ",
"acceptedAnswer": {
"@type": "Answer",
"text": "With myEV point application, it is very convenient to find your nearby electric vehicle charging station. Apart from this, electric vehicles nowadays come equipped with a navigation system which provides a database of nearby EV charging stations.	"
}

},{
"@type": "Question",
"name": "What safety considerations should I be aware of when using charging stations? ",
"acceptedAnswer": {
"@type": "Answer",
"text": "Before initiating charging, you should check for any signs of damage in the charging station as well as the charging cable. Apart from this, you should check whether the charging station is compatible with the vehicle or not."
}

},{
"@type": "Question",
"name": "Are there any special maintenance requirements for electric vehicles?",
"acceptedAnswer": {
"@type": "Answer",
"text": "As electric vehicles do not have many moving parts, so requirement of maintenance is very less but users just need to take care of the performance of the battery as well get their vehicle inspected timely in order to make sure that all the components are in good condition."
}

},{
"@type": "Question",
"name": "How often do I need to service my electric vehicle? ",
"acceptedAnswer": {
"@type": "Answer",
"text": "Although the requirement of servicing is very less as compared to traditional vehicles, it still totally depends upon the recommendations of the manufacturer and your driving habits."
}

},{
"@type": "Question",
"name": "Can I charge my electric vehicle in rainy conditions?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Electric vehicle charging stations are weather-resistant, so they can be charged in any weather condition."
}

},{
"@type": "Question",
"name": "What is an AC charging station?  ",
"acceptedAnswer": {
"@type": "Answer",
"text": "An AC charging station is a type of charging through which power is converted into DC inside the electric vehicle. Level 1 and Level 2 charging come under the AC charging category."
}

},{
"@type": "Question",
"name": "What types of connectors are used for AC charging?",
"acceptedAnswer": {
"@type": "Answer",
"text": "In India, most commonly, type 2 chargers are used for AC charging. It is IEC 62196 (Type 2), it is commonly used in Europe and is widely adopted in India also."
}

},{
"@type": "Question",
"name": "How long does it take to charge an electric vehicle at an AC charging station?",
"acceptedAnswer": {
"@type": "Answer",
"text": "It depends upon the type of charger used. If you are using Level 1 charging which requires 120V supply add 2 to 5 miles of range per hour; However, Level 2 Charging usually requires 240V supply and adds 10 to 25 miles of range per hour."
}

},{
"@type": "Question",
"name": "Can I install an AC charging station at home?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes, both level 1 and Level 2 electric vehicle charging stations can easily be installed at home. For the former one, a standard household outlet can be used for charging, but for the latter one, professional help is required to set up a special equipment."
}

},{
"@type": "Question",
"name": "Why is DC charging faster than AC?",
"acceptedAnswer": {
"@type": "Answer",
"text": "DC charging is faster than AC because of high voltage requirements of between 200 and 1000 volts. Moreover, they bypass the vehicle's on-board charger and supply DC power directly to the vehicle."
}

},{
"@type": "Question",
"name": "How does a DC fast charging station work?",
"acceptedAnswer": {
"@type": "Answer",
"text": "DC fast charging stations supply DC power directly to the vehicle by eliminating the need of an on-board charger and add 60 to 100 miles of range per 25 to 3o minutes."
}

},{
"@type": "Question",
"name": "How long does it take to charge an electric vehicle at a DC fast charging station?",
"acceptedAnswer": {
"@type": "Answer",
"text": "The DC fast electric vehicle charging stations add 60 to 100 miles of range per 25 to 30 minutes."
}

},{
"@type": "Question",
"name": "Where can I find DC fast charging stations near me?",
"acceptedAnswer": {
"@type": "Answer",
"text": "For finding a DC electric vehicle charging station, you can use myEV point mobile application or you can use your  vehicle’s navigation system which comes equipped with a database of nearby charging stations."
}
        }]
    }
            }`}
            />
            <Header />
            <InnerPageBanner title='FAQ About EV Charging Station' maxWidth='472px' innerPageBanner={aboutBanner} />
            <section className="py-20">
                <div className="container m-auto px-[15px]">
                    <div className='grid md:grid-cols max-md:grid-cols-1 md:gap-6 px-[15px]'>
                        <div>
                            {dummyData.filter((_, i) => i % 2 === 0).map((row, i) => (
                                <div key={i} className='faq-info mb-5'>
                                    <button onClick={() => toggleHandler(row.id)} className={`${expanded === row.id && "bg-[#0B7132] text-white"} w-full px-4 py-4 text-left cursor-pointer rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] transition-all duration-200`}>
                                        <div className='faq-title flex justify-between items-baseline'>
                                            <h4 className='font-normal text-[20px] max-md:leading-6'>{row.title}</h4>
                                            {expanded === row.id ? (
                                                <FaChevronUp size="15" />
                                            ) : (
                                                <FaChevronDown size="15" />
                                            )}
                                        </div>
                                    </button>
                                    <div className={`faq-content mt-5 pb-5 px-5 ${expanded === row.id ? "block" : "hidden"}`}>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: row.description }}
                                            className='text-black select-none '
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            {dummyData.filter((_, i) => i % 2 !== 0).map((row, i) => (
                                <div key={i} className='faq-info mb-5'>
                                    <button onClick={() => toggleHandler(row.id)} className={`${expanded === row.id && "bg-[#0B7132] text-white"} w-full px-4 py-4 text-left cursor-pointer rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] transition-all duration-200`}>
                                        <div className='faq-title flex justify-between items-baseline'>
                                            <h4 className='font-normal text-[20px] max-md:leading-6'>{row.title}</h4>
                                            {expanded === row.id ? (
                                                <FaChevronUp size="15" />
                                            ) : (
                                                <FaChevronDown size="15" />
                                            )}
                                        </div>
                                    </button>
                                    <div className={`faq-content mt-5 pb-5 px-5 ${expanded === row.id ? "block" : "hidden"}`}>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: row.description }}
                                            className='text-black select-none '
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

const dummyData = [
    {
        id: 1,
        title: "What is an electric vehicle (EV) and how does it work?",
        description: "An <b>electric vehicle</b> is a mode of transportation which uses electricity to power up rather than using fossil fuels like petrol and diesel. These vehicles are equipped with batteries that are charged with the help of electricity  and provide power to the motor in the vehicle."
    },
    {
        id: 2,
        title: "How environmentally friendly are electric vehicles?",
        description: "Electric vehicles come without tailpipes because they do not run on gasoline. So, they do not produce any harmful emissions. Moreover, the environmental effect can further be reduced by using renewable forms of generating electricity."
    },
    {
        id: 3,
        title: "What are the benefits of driving an electric car?",
        description: `
            <ul class="list-disc list-inside">
                <li class="mb-3">Environmentally friendly solutions, as they do not emit gases like carbon monoxide and hydrocarbons produced by traditional vehicles.</li>
                <li class="mb-3">Renewable forms of generating electricity like solar and wind power can be used, further reducing the environmental impact and proving to be cost-effective.</li>
                <li class="mb-3">Lower running and maintenance costs.</li>
                <li class="mb-3">Tax and other financial benefits are offered by governments to EV owners.</li>
                <li>Can be charged at home.</li>
            </ul>
        `
    },
    {
        id: 4,
        title: "What is the difference between AC and DC EV charging?",
        description: `
        <table class=" w-[95%] mx-auto border-collapse border border-slate-400">
        <tr>
                <th class="w-[50%] p-3 border-collapse border border-slate-400">AC Charging</th>
                <th class="w-[50%] p-3 border-collapse border border-slate-400">DC Charging</th>
               
            </tr>
            <tr>
                <td class="p-2 border-collapse border border-slate-400">The power is converted from AC to DC inside the electric vehicle.</td>
                <td class="p-2 border-collapse border border-slate-400">The power is converted from AC to DC inside the electric vehicle charging station.</td>
                
            </tr>
            <tr>
                <td class="p-2 border-collapse border border-slate-400">These are suitable for residential installation.</td>
                <td class="p-2 border-collapse border border-slate-400">The DC charging stations are usually found along the highways and are not suitable for home installation because of high voltage requirements.</td>
                
            </tr>
            <tr>
                <td class="p-2 border-collapse border border-slate-400">The shape of the charging curve is a straight line.</td>
                <td class="p-2 border-collapse border border-slate-400">Here, the line shows a degrading trend.</td>
                
            </tr>
            <tr>
                <td class="p-2 border-collapse border border-slate-400">Do not have any effect on the performance.</td>
                <td class="p-2 border-collapse border border-slate-400">Prolonged charging with <b>DC charging</b> can slightly degrade the battery over time.</td>
            </tr> 
            <tr>
                <td class="p-2 border-collapse border border-slate-400">Cost-effective.</td>
                <td class="p-2 border-collapse border border-slate-400">Expensive.</td>
            </tr>
        </table>
    `    },
    {
        id: 5,
        title: "Can I charge my car on any charger?",
        description: "It is important to check the compatibility of vehicles with different types of charger. Also, modern electric vehicles come up with a connector or an adapter which allow them to be charged at any station."
    },
    {
        id: 6,
        title: "Which type of charging station is better for electric cars?",
        description: "Although all types of <b>electric vehicle charging stations</b> are suitable for EVs, it depends on the driving habits and the needs of the drivers. If a person has to use it for a short distance, then level 1 is also suitable and if one wants faster charging, then one can go for level 2 also. Sometimes people want quick top-ups, so that time, people can choose <b>DC fast charging</b> for their EVs."
    },
    {
        id: 7,
        title: "How many types of EV charging stations are available at myEVPoint?",
        description: "<b>My EV point</b> offers both AC and <b>DC charging stations</b> for its users."
    },
    {
        id: 8,
        title: "How can I find nearby electric vehicle charging stations?",
        description: "With <b>myEV point application</b>, it is very convenient to find your nearby electric vehicle charging station. Apart from this, electric vehicles nowadays come equipped with a navigation system which provides a database of nearby <b>EV charging stations.</b>"
    },
    {
        id: 9,
        title: "What safety considerations should I be aware of when using charging stations?",
        description: "Before initiating charging, you should check for any signs of damage in the charging station as well as the charging cable. Apart from this, you should check whether the <b>charging station</b> is compatible with the vehicle or not."
    },
    {
        id: 10,
        title: "Are there any special maintenance requirements for electric vehicles?",
        description: "As <b>electric vehicles</b> do not have many moving parts, so requirement of maintenance is very less but users just need to take care of the performance of the battery as well get their vehicle inspected timely in order to make sure that all the components are in good condition."
    },
    {
        id: 11,
        title: "How often do I need to service my electric vehicle?",
        description: "Although the requirement of servicing is very less as compared to traditional vehicles, it still totally depends upon the recommendations of the manufacturer and your driving habits."
    },
    {
        id: 12,
        title: "Can I charge my electric vehicle in rainy conditions? ",
        description: "<b>Electric vehicle charging stations</b> are weather-resistant, so they can be charged in any weather condition."
    },
    {
        id: 13,
        title: "What steps can I take to maximise the battery life of my electric vehicle?",
        description: `
        <ul class="list-disc list-inside">
        <li class="mb-3">Avoid fully charging your vehicle.</li>
        <li class="mb-3">Avoid parking your vehicle in extreme-temperatures.</li>
        <li class="mb-3">Drive efficiently</li>
        <li class="mb-3">Limit fast charging</li>
        <li>Minimise heavy loads.</li>
        </ul>
        `
    },


]

export default Faq;