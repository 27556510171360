import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/EVChargingStationVideos.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import React from "react";
import SEO from '../common/SEO';
import Videos from '../components/Videos/Videos';

const Video = () => {

    return (
        <>
            <SEO title='Video Showcase of EV Charging Stations | MyEV Point' description='Discover a video showcase highlighting electric vehicle charging stations and latest events. Watch informative and inspiring videos. Visit myevpoint.in now!' />
            <Header/>
            <InnerPageBanner title='Videos' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Videos/>
            <ContactInfo/>
            <Footer/>
        </>
    )
}

export default Video;
