import React, { useState } from "react";
import Grid from "@mui/material/Grid";
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
// import './App.css';
import VerifiedIcon from '@mui/icons-material/Verified';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
// import NewsAdded from "./newsAdded";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 292,
  bgcolor: 'background.paper',

  border: '1px solid #000',
  p: 4,
  textAlign: 'center'
};

const Addlocation = ({ setTrigger }) => {
  const [name, setName] = useState("")
  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")

  const [open, setOpen] = useState(false);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false) }

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, ''); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setLatitude(numericValue);

    }
  };
  const handlePhoneInputChanges = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, ''); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setLongitude(numericValue);

    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "") {
      alert("City name is required")
    } else if (latitude === "") {
      alert("Latitude is required")
    }
    
     else if (longitude === "") {
      alert("Longitude is required")
   
    } else {
      try {
        await axios.post(`${process.env.REACT_APP_URL}/user/sendlocation`, {
          name: name,
          latitude: latitude,
          longitude: longitude,
        });
        alert("Location added successfully")
        setName('')
        setLatitude('')
        setLongitude('')
        setTrigger(prev => prev + 1);
      } catch (error) {
      }
    }
  };
  return (
    <>
      <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px]">
        <h1 className="uppercase">location</h1>
        <form
          action="/addNews"
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <Grid className="mb-3" container spacing={2} md={8}>
            <Grid item md={12}>

              <label className="form-label">City Name</label>

              <input
                className="inpit_row form-input"
                name="title"
                type="text"
                value={name}
                maxLength="30"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter City Name"
              />

            </Grid>
            <Grid item md={6}>

              <label className="form-label">Latitude</label>

              <input
                className="inpit_row form-input"
                name="title"
                type="text"
                value={latitude}
                maxLength={10}
                onChange={(e) => handlePhoneInputChange(e)}
                placeholder="Enter Location Latitude"
              />

            </Grid>
            <Grid item md={6}>

              <label className="form-label">Longitude</label>
              <input
                className="inpit_row form-input"
                name="title"
                type="text"
                value={longitude}
                maxLength={10}
                onChange={(e) => handlePhoneInputChanges(e)}
                placeholder="Enter Location Longitude"
              />

            </Grid>


          </Grid>

          <button className="form-action w-60 " type="Submit">
            Add
          </button>
        </form>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2" style={{ paddingTop: "20px" }}>
              News Add Successfully <VerifiedIcon style={{ position: "absolute", top: "20px", left: "46%", color: "#1aa13e", fontSize: "30px" }} />
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
  ;
}

export default Addlocation;
