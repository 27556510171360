
import PortalHeader from "../components/admin/portalheader/Portalheader";
import Subscribers from "../components/admin/subscribers/Subscriber";
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const Subscriber = () => {
    const navigate = useNavigate();
    const storedUserInfo = sessionStorage.getItem('userInfo');
    useEffect(() => {
        if (!storedUserInfo) {
            navigate("/admin-login");
        }
    }, [storedUserInfo, navigate]);
  
    return (
        <>
            <PortalHeader>
                <Subscribers/>
            </PortalHeader>
        </>
    )
};

export default Subscriber;
