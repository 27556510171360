import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Delete from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
  width: "60%",
  borderRadius: 8,
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-center",
  paddingLeft: "3em",
  marginBottom: "2em",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Alllocation = ({ trigger }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [originalFormData, setOriginalFormData] = useState({});


  const handleClose = () => {
    setFormDataUpdate(originalFormData);
    setOpen(false);
  };
  const [formDataUpdate, setFormDataUpdate] = useState({
    id: "",
    name: "",
    latitude: "",
    longitude: "",
    isActive: true
  });

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (selectedData) {
      setFormDataUpdate({
        id: selectedData._id,
        name: selectedData.name,
        latitude: selectedData.latitude,
        longitude: selectedData.longitude,
        isActive: selectedData.isActive
      });
    }
  }, [selectedData]);

  const handleChangeUpdate = (e) => {
    const { name } = e.target;
    if (name === "isActive") {
      setFormDataUpdate((prevData) => ({
        ...prevData,
        [name]: !prevData.isActive,
      }));
    } else if (name === "latitude") {
      const inputValue = e.target.value;
      const numericValue = inputValue.replace(/[^\d.]/g, '');
      setFormDataUpdate((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    }
    else if (name === "longitude") {
      const inputValue = e.target.value;
      const numericValue = inputValue.replace(/[^\d.]/g, '');
      setFormDataUpdate((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    }
    else {
      setFormDataUpdate((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };

  const handleSettings = (newdata) => {
    setSelectedData(newdata);
    setOriginalFormData({
      id: newdata._id,
      name: newdata.name,
      latitude: newdata.latitude,
      longitude: newdata.longitude,
      isActive: newdata.isActive,
    });
    setOpen(true);
  };

  const handleEdit = async (id) => {
   
      try {
        await axios.put(`${process.env.REACT_APP_URL}/user/editlocation/${id}`, formDataUpdate);
        alert("Location updated") 
        setOpen(false);
      } catch (error) {
      }
      setTimeout(() => {
        fetchData();
      }, 2000)
    };
   

    const handleDelete = (itemId) => {
      setDeleteItemId(itemId);
      setDeleteConfirmationOpen(true);
    };

    const handleYesDelete = async () => {
      try {
        await axios.delete(`${process.env.REACT_APP_URL}/user/deletelocation/${deleteItemId}`);
        const updatedData = data.filter((item) => item._id !== deleteItemId);
        setData(updatedData);
      } catch (error) {
      } finally {
        setDeleteConfirmationOpen(false);
      }
    };

    const handleNoDelete = () => {
      setDeleteConfirmationOpen(false);
    };

    const fetchData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/getlocation`);
      setData(response?.data?.data)
    }

    useEffect(() => {
      fetchData();
    }, [trigger]);



    return (
      <>
        <TableContainer sx={{ maxWidth: 1100 }}>
          <Table aria-label="sticky table" className="w-100 mt-16">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ maxWidth: 20 }}>
                  <strong>No.</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Name</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Latitude</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Longitude</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Status</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Edit</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <strong>Delete</strong>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {data.map((data, index) => (
              <TableBody key={data._id}>
                <StyledTableRow>
                  <StyledTableCell sx={{ maxWidth: 20 }} component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{data?.name}</StyledTableCell>
                  <StyledTableCell>{data?.latitude}</StyledTableCell>
                  <StyledTableCell>{data?.longitude}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton style={{ color: data?.isActive ? "#0B7132" : "#e50914" }} aria-label="delete" size="medium">
                      <CheckCircleOutlineIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton color="primary" aria-label="Edit" size="medium" onClick={() => handleSettings(data)}>
                      <ModeEditIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton style={{ color: "#000000" }} aria-label="Delete" size="medium" onClick={() => handleDelete(data?._id)}>
                      <Delete />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <div className='dashboard'>
            <Box sx={style}>
              <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px]">
                <form
                  style={formStyle}
                  action="/addNews"
                  method="post"
                  encType="multipart/form-data"
                  onSubmit={(e) => { e.preventDefault(); handleEdit(formDataUpdate.id); }}
                >
                  <div className="flex items-center justify-between w-full">
                    <h1 className="uppercase">Update Location</h1>
                    <FormGroup className="ml-20">
                      <FormControlLabel
                        className="ml-20"
                        control={
                          <Switch
                            name="isActive"
                            checked={formDataUpdate.isActive}
                            onChange={handleChangeUpdate}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </FormGroup>
                  </div>

                  <Grid className="mb-3 flex" container spacing={2} md={8}>
                    <Grid item md={12}>
                      <label className="form-label">Name</label>
                      <input
                        className="inpit_row form-input"
                        name="name"
                        type="text"
                        maxLength={30}
                        value={formDataUpdate.name}
                        onChange={handleChangeUpdate}
                        placeholder="Enter your Name"
                      />
                    </Grid>

                  </Grid>

                  <Grid className="mb-3 flex" container spacing={2} md={8}>
                    <Grid item md={6}>
                      <label className="form-label">Latitude</label>
                      <input
                        className="inpit_row form-input"
                        name="latitude"
                        type="text"
                        maxLength={10}
                        value={formDataUpdate.latitude}
                        onChange={handleChangeUpdate}
                        placeholder="Enter your Name"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <label className="form-label ">Longitude</label>
                      <input
                        className="inpit_row form-input"
                        name="longitude"
                        type="text"
                        maxLength={10}
                        value={formDataUpdate.longitude}
                        onChange={handleChangeUpdate}
                        placeholder="Enter your Name"
                      />
                    </Grid>
                  </Grid>
                  <button
                    className="form-action w-60 py-3"
                    type="Submit"
                    style={{ borderRadius: "4px" }}
                  >
                    Update
                  </button>
                </form>
              </div>
            </Box>
          </div>
        </Modal>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleNoDelete}
          aria-labelledby="delete-confirmation-dialog-title"
          aria-describedby="delete-confirmation-dialog-description"
        >
          <DialogTitle id="delete-confirmation-dialog-title">Delete Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this location?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleYesDelete} variant="contained" color="primary">
              Yes
            </Button>
            <Button onClick={handleNoDelete} variant="contained" style={{ backgroundColor: "#333333", color: "#ffffff" }}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  export default Alllocation;
