import React from "react";
import { FaToolbox, FaMoneyBillAlt, FaUsersCog, FaVial, FaAward, FaRecycle  } from "react-icons/fa";
import Counting from "../../components/counting/Counting";
import Images from "../images/Images";

const Partners = () => {
    return(
        <>
            <div className="w-full pt-20 pb-5">
                <div className="container mx-auto px-2">
                    <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col mb-10">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaToolbox className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Lucrative Revenue</h4>
                                    <p className="text-sm">Open up a world of lucrative revenue opportunities by catering to the growing market of EV.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaMoneyBillAlt className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Competitive Pricing</h4>
                                    <p className="text-sm">Get competitive pricing and attractive discounts and maximise your profits along with offering competitive rates to the customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaUsersCog className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Technical Expertise and Training</h4>
                                    <p className="text-sm">Enjoy access to our technical training and get equipped with knowledge and skills required to run your business.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col md:mb-5">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaVial className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Extensive product Portfolio</h4>
                                    <p className="text-sm">Get access to our extensive portfolio of high quality products. Our diverse offerings allow you to serve varying needs and preferences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaAward className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Brand Recognition</h4>
                                    <p className="text-sm">Our earned name in the market provides you with a competitive advantage and reputation in the industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaRecycle className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Uplift Green Mobility</h4>
                                    <p className="text-sm">Invest in electric vehicle charging infrastructure and contribute towards building a cleaner, greener and more sustainable tomorrow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container mx-auto px-2">
                    <Counting/>
                </div>
            </div>
            <Images/>
        </>
    )

}
export default Partners;