import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from "react-router-dom"
import Header from '../components/header/Header'
import ServicesDetails from "../components/servicesDetail/ServicesDetail"
import Footer from '../components/footer/Footer'
import WhyUs from '../components/servicesDetail/WhyUS'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'
import servicesData from "../data/services.json";

const ServicesDetail = () => {
    // useEffect(() => {
    //     const selectedService = servicesData.find(service => service.type === type);
    //     setServiceDetails(selectedService);
    // }, [type])
    const { type } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [serviceDetails, setServiceDetails] = useState(null);
    
    useEffect(() => {
        const selectedService = servicesData.find(service => service.type === type);
        setServiceDetails(selectedService);
        const correctedSlug = type.toLowerCase();
        if (type !== correctedSlug) {
            navigate(`/services/${correctedSlug}`, { replace: true });
        }
        // Check if the URL has a trailing slash
        if (location.pathname.endsWith("/") && location.pathname !== "/") {
            const newPath = location.pathname.replace(/\/$/, "");
            // Modify history using window.history.replaceState() instead of navigate()
            window.history.replaceState(null, "", newPath);
      }
    }, [type, navigate, location.pathname]);

    return (
        <>
            <SEO title={serviceDetails?.metaTitle} description={serviceDetails?.metaDescription} keywords={serviceDetails?.keyword}/>
            <Header />
            <InnerPageBanner title={serviceDetails?.bannerTitle} maxWidth='472px' innerPageBanner={serviceDetails?.bannerimage}/>
            <ServicesDetails />
            <WhyUs />
            <ContactInfo />
            <Footer />
        </>
    )
}

export default ServicesDetail;
