import PortalHeader from "../components/admin/portalheader/Portalheader";
import React from "react";
import Contact from "../components/admin/contact/Contact";

const ContactList = () => {
    return (
        <>
            <PortalHeader>
                <Contact />
            </PortalHeader>
        </>
    )
};

export default ContactList;