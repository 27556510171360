import React from 'react';
import "./Footer.css";
import ImageIcons from '../../common/ImageIcons'
import { BiLogoFacebook } from "react-icons/bi";
import { FaRegEnvelope, FaTwitter, FaYoutube, FaPinterest, FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdLocationPin, MdPhone, MdArrowUpward } from "react-icons/md";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { windowScroll } from '../../helpers/ScrollToTop';
import { windowScrollTop } from '../../helpers/ScrollToTop';
import brocher from "../../assets/utils/myEV_borcher.pdf"
import footerleftbg from "../../assets/Images/footer-left-bg.webp";
import footerrightbg from "../../assets/Images/footer-right-bg.webp";
import useScrollPosition from '../shared/useScrollPosition';
import FloatingActions from '../shared/floatingAction';

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

const Footer = () => {
    const scrollPosition = useScrollPosition();
    const location = useLocation();
    const { pathname } = location;
    const currentYear = new Date().getFullYear();
    const phoneNumber = '+91 9592-595975';
    const phoneLink = `tel:${phoneNumber}`;
    const callPhoneNumber = () => {
        window.location.href = phoneLink;
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };

    return (
        <>
            {/* Footer Section Start */}
            <footer className="w-full bg-[#212226]">
                <div className="footer-sec-info">
                    <div className="footer-top-info max-sm:pb-6 sm:pb-2 md:pb-2 lg:pb-20" style={{ backgroundImage: `url(${footerleftbg}), url(${footerrightbg})` }}>
                        <div className="container m-auto">
                            <div className="grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-4 md:1 max-lg:gap-3 lg:gap-2 xl:gap-4">
                                <div className="footer-col-first max-md:mb-6 max-lg:mb-8">
                                    <Link to='/' onClick={windowScrollTop}>
                                        <div className='site-logo-footer max-lg:mb-3 lg:mb-5'>
                                            <img src={ImageIcons.footer_myEV_logo} alt='MyEV Point Logo' className='lg:max-w-[160px] max-lg:max-w-[110px]' />
                                        </div>
                                    </Link>
                                    <div className='max-lg:mb-5 lg:mb-9'>
                                        <p className='text-white'>
                                            myEV point, a preeminent charging station service provider offers impeccable expertise and seamless solutions assisting you in having your own station  without any burden of its management.
                                        </p>
                                    </div>
                                    <div className="social-links-info flex flex-wrap gap-2">
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://www.facebook.com/myevpoint' target="_blank"><BiLogoFacebook className='h-[40px] w-[40px] p-2 text-white' /></Link>
                                        </div>
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://www.instagram.com/myevpoint/' target="_blank"><GrInstagram className='h-[34px] w-[34px] p-2 text-white' /></Link>
                                        </div>
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://twitter.com/myevpoint' target="_blank"><FaTwitter className='h-[42px] w-[42px] p-3 text-white' /></Link>
                                        </div>
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://in.pinterest.com/myevpoint/' target="_blank"><FaPinterest className='h-[42px] w-[42px] p-3 text-white' /></Link>
                                        </div>
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://www.youtube.com/channel/UCI3qj7D2eG-hqQ-HP948QWw ' target="_blank"><FaYoutube className='h-[45px] w-[45px] p-3 text-white' /></Link>
                                        </div>
                                        <div className="socila-link-info flex justify-center items-center w-10 h-10 rounded-full hover:scale-[1.1] hover:bg-[#0B7132]">
                                            <Link to='https://www.linkedin.com/company/my-ev-point/' target="_blank"><FaLinkedinIn className='h-[42px] w-[42px] p-3 text-white' /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-col-second max-md:mb-6 max-lg:mb-8">
                                    <div className='max-lg:mb-3 lg:mb-10 pb-2'>
                                        <h4 className='text-white font-medium mb-2'>Our Services</h4>
                                        <hr className='green-hr-line' />
                                    </div>
                                    <ul className='footer-links-info'>
                                        <li className='mb-2'>
                                            <NavLink to='/services/ac-charging-station' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/services/ac-charging-station' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>AC Charger Services</p>
                                            </NavLink>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/services/dc-charging-station' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/services/dc-charging-station' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>DC Charger Services</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/products' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/products' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Products</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/states' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/states' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>States</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/blogs' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/blogs' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Blogs</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/faqs' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/faqs' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>FAQs</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-col-three max-md:mb-6 max-lg:mb-8">
                                    <div className='max-lg:mb-3 lg:mb-10 pb-2'>
                                        <h4 className='text-white font-medium mb-2'>Useful Links</h4>
                                        <hr className='green-hr-line' />
                                    </div>
                                    <ul className='footer-links-info list-none'>
                                        <li className='mb-2'>
                                            <Link to='/careers' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/careers' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Careers</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/privacy-policy' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/privacy-policy' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Privacy Policy</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/terms-and-conditions' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/terms-and-conditions' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Terms & Conditions</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>

                                            <Link to='/refund-policy' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/refund-policy' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Refund Policy</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/cancellation-policy' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon Icon' className='h-4 w-4' />
                                                <p className={`${pathname === '/cancellation-policy' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Cancellation Policy</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link className="flex items-center gap-2 cursor-pointer" to={brocher} download="myEV_Point" target="_blank" rel="noopener noreferrer">
                                                <img src={ImageIcons.Polygon} alt='Polygon' className='h-4 w-4' />
                                                <p className="text-white hover:text-[#0B7132]">Download our Brochure</p>
                                            </Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to='/sitemap.html' className="flex items-center gap-2 cursor-pointer" onClick={windowScroll}>
                                                <img src={ImageIcons.Polygon} alt='Polygon' className='h-4 w-4' />
                                                <p className={`${pathname === '/sitemap.html' ? "text-[#0B7132] font-semibold" : "text-white hover:text-[#0B7132]"}`}>Sitemap</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-col-four max-md:mb-6 max-lg:mb-8">
                                    <div className='max-lg:mb-3 lg:mb-10 pb-2'>
                                        <h4 className='text-white font-medium mb-2'>Contact Info</h4>
                                        <hr className='green-hr-line'/>
                                    </div>
                                    <ul className="list-none">
                                        <li className="flex mb-4">
                                            <MdLocationPin className='flex-none max-md:h-7 max-md:w-7 max-md:me-1 md:h-7 md:w-7 md:me-2 xl:h-8 xl:w-8 xl:me-2 text-[#0B7132]' />
                                            <p className="text-white">Hall 3, IT Wing, B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                                        </li>
                                        <li className="flex mb-4">
                                            <FaRegEnvelope className='flex-none max-md:h-5 max-md:w-5 max-md:me-3 md:h-5 md:w-5 md:me-4 xl:h-6 xl:w-6 xl:me-4 text-[#0B7132] ' />
                                            <p onClick={openMail} className="text-white cursor-pointer">{email}</p>
                                        </li>
                                        <li className="flex">
                                            <MdPhone className='flex-none max-md:h-6 max-md:w-6 max-md:me-1 md:h-6 md:w-6 md:me-2 xl:h-7 xl:w-7 xl:me-3 text-[#0B7132] ' />
                                            <p className="text-white cursor-pointer" onClick={callPhoneNumber} >{phoneNumber}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Copyright Content */}
                    <div className="footer-copyright-info bg-[#212226]">
                        <div className="container px-2 max-md:py-4 md:py-6 mx-auto">
                            <div className='md:flex md:gap-5 md:justify-between'>
                                <p className="text-[#fff] max-md:mb-2 max-md:text-center">Powered by VP CLEAN ENERGY PRIVATE LIMITED</p>
                                <p className="text-white max-md:text-center">Copyright {currentYear}  by VP Clean Energy. All Right Reserved.</p>
                            </div>
                        </div>
                    </div>
                    {scrollPosition > 600 && (
                        <div className='fixed z-50'>
                            <button className='itms-center fixed  group md:right-[50px] md:bottom-[50px] duration-500 right-[15px] bottom-[15px] bg-[#F1F8E8] hover:bg-[#0B7132] w-[50px] h-[50px] shadow-md rounded-[10px] shadow-[#000]' onClick={windowScrollTop}>
                                <MdArrowUpward className="text-[#0B7132] group-hover:text-white mx-auto text-3xl" />
                            </button>
                        </div>
                    )}
                    <FloatingActions />
                </div>
            </footer>
            {/* Footer Section End */}
        </>
    )
}

export default Footer;