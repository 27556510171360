import PortalHeader from "../components/admin/portalheader/Portalheader";
import AddGallery from "../components/admin/gallery/Addgallery";
import AllGallery from "../components/admin/gallery/Allgallery";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const Admingallery = () => {
    const [trigger, setTrigger] = useState(0)
    const navigate = useNavigate();
    const storedUserInfo = sessionStorage.getItem('userInfo');
    useEffect(() => {
        if (!storedUserInfo) {
            navigate("/admin-login");
        }
    }, [storedUserInfo, navigate]);
    
    return (
        <>
            <PortalHeader>
                <AddGallery setTrigger={setTrigger}/>
                <AllGallery trigger={trigger}/>
            </PortalHeader>
        </>
    )
};

export default Admingallery;