import React from "react";
import Header from '../components/header/Header'
import NotFoundPage from "../common/NotFound";
import Footer from "../components/footer/Footer";

const NotFound = () => {
    return (
        <>
            <Header />
                <NotFoundPage />
            <Footer />
        </>
    )
}

export default NotFound;