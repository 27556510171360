import React, { useState } from "react";
import { FaTwitter, FaYoutube, FaPinterest, FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { BiLogoFacebook } from "react-icons/bi";
import { MdPhone, MdLocationPin } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { windowScroll } from "../../helpers/ScrollToTop";
import { validateEmail } from '../../utils/formFunctions';
import axios from 'axios';
import "./ContactUs.css";
import Loader from "../shared/loader";

const Contact = () => {
    const phoneNumber = '+91 9592-595975';
    const phoneLink = `tel:${phoneNumber}`;
    const navigate = useNavigate();
    const callPhoneNumber = () => {
        window.location.href = phoneLink;
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };
    const handleClick = () => {
        window.open("https://www.google.com/maps/dir//My+EV+Point+Charging+Station+Hall+3,+IT+Wing+B-70,+beside+Falcon+Enterprises,+Phase+7,+Industrial+Area,+Sector+74+Sahibzada+Ajit+Singh+Nagar,+Punjab+160055/@30.7132965,76.6942769,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390fef51d3a3d00b:0x92c78e59f10fa179!2m2!1d76.6942769!2d30.7132965?entry=ttu");
    };
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState({
        email: false,
        msg: false,
        phone: false,
        message: "",
        success: false
    });
    const [formInput, setFormInput] = useState({
        firstName: "",
        email: "",
        phone: "",
        message: "",
        stdCode: "+91"
    })
    const firstNameHandler = (e) => {
        const inputValue = e.target.value;
        const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Allow spaces
        // Capitalize the first letter of each word
        const capitalizedValue = alphaValue.replace(/\b\w/g, (match) => match.toUpperCase());
        if (capitalizedValue.length <= 30) {
            setFormInput((prevState) => ({ ...prevState, firstName: capitalizedValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    const handlePhoneInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        if (numericValue.length <= 10) {
            setFormInput((prevState) => ({ ...prevState, phone: numericValue }));
            setError((prevState) => ({ ...prevState, phone: false, message: '' }));
        }
    };

    const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;
    const submissionHandler = (e) => {
        e.preventDefault();
        setDisabled(true);
        setError((prevState) => ({ ...prevState, message: "" }));
        if (!formInput.firstName || !formInput.email || !formInput.phone) {
            setError((prevState) => ({
                ...prevState,
                success: false,
                message: "Fields must not be empty!",
            }));
            setDisabled(false);
        } else if (!validateEmail(formInput.email)) {
            setError((prevState) => ({
                ...prevState,
                email: true,
                message: "Email is invalid!",
            }));
            setDisabled(false);
        }
        else if (!validRegex.test(formInput.phone)) {
            setError((prevState) => ({
                ...prevState,
                phone: true,
                message: "Phone number must be valid !",
            }));
            setDisabled(false);
        } else {
            axios
                .post(`${process.env.REACT_APP_URL}/user/sendemail`, {
                    fullName: formInput.firstName,
                    email: formInput.email.toLowerCase(),
                    number: `${formInput.stdCode} ${formInput.phone}`,
                    message: formInput.message,
                })
                .then((response) => {
                    console.log("response>>>", response)
                    setError((prevState) => ({ ...prevState, success: true }));
                    setTimeout(() => {
                        setError((prevState) => ({ ...prevState, success: false }));
                        setDisabled(false);
                        setFormInput({
                            firstName: "",
                            email: "",
                            phone: "",
                            message: "",
                            stdCode: "+91",
                        });
                        windowScroll();
                        navigate("/thank-you");
                    }, 3000);
                })
                .catch((error) => {
                    setDisabled(false);
                });
        }
    };

    return (
        <>
            {/* {/ Contact Form Section /} */}
            <div className="contact-form-section w-full py-20">
                <div className="container mx-auto px-2">
                    <div className="flex lg:gap-10 max-lg:gap-5 max-md:flex-col items-center">
                        <div className="md:w-1/2 max-md:mb-8">
                            <div className="content-form-txt">
                                <h2 className="mb-3">Get In Touch</h2>
                                <p className="mb-7 max-w-[500px]">Stay ahead of the curve with our state-of-the-art charging infrastructure. We're committed to embracing the latest in EV charging technology to keep you plugged in efficiently.</p>
                                <div className="social-media-links flex flex-wrap gap-3">
                                    <Link to='https://www.facebook.com/myevpoint' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-3 hover:scale-[1.1]">
                                            <BiLogoFacebook className='h-6 w-6 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                    <Link to='https://www.instagram.com/myevpoint/' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-3 hover:scale-[1.1]">
                                            <GrInstagram className='h-5 w-5 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                    <Link to='https://twitter.com/myevpoint' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-[13px] hover:scale-[1.1]">
                                            <FaTwitter className='h-5 w-6 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                    <Link to='https://in.pinterest.com/myevpoint/' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-[13px] hover:scale-[1.1]">
                                            <FaPinterest className='h-5 w-6 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                    <Link to='https://www.youtube.com/channel/UCI3qj7D2eG-hqQ-HP948QWw' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-[13px] hover:scale-[1.1]">
                                            <FaYoutube className='h-5 w-6 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                    <Link to='https://www.linkedin.com/company/my-ev-point/' target="_blank">
                                        <div className="w-12 h-12 rounded-full bg-[#F1F8E8] p-[13px] hover:scale-[1.1] h">
                                            <FaLinkedinIn className='h-5 w-6 text-[#0B7132]' />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 max-md:w-full max-md:px-2 relative">
                            <div className="contact-us-form-info bg-[#fff] rounded-[10px] shadow-[0_2px_20px_10px_rgba(0,0,0,0.1)] md:py-14 max-md:py-8 md:px-10 max-md:px-5">
                                <>
                                    {disabled && (<Loader />)}
                                    <form>
                                        <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${error.message ? 'block' : 'hidden '}`}>{error.message}</p>
                                        <p className=" font-medium text-black">Full Name</p>
                                        <div className="relative mb-6">
                                            <input disabled={disabled} placeholder='Enter your full name' id="firstname-input" value={formInput.firstName} onChange={(e) => firstNameHandler(e)} className="bg-[#F1F8E8] rounded-lg block text-gray-900 w-full p-4 focus:outline-none"/>
                                        </div>
                                        <p className=" font-medium text-black">Email Address</p>
                                        <div className="relative mb-6">
                                            <input
                                                minRows={3}
                                                maxRows={5}
                                                disabled={disabled}
                                                error={error.email}
                                                placeholder='example@gmail.com'
                                                id="email-input"
                                                value={formInput.email}
                                                onChange={(e) => {
                                                    setFormInput(prevState => ({ ...prevState, email: e.target.value }));
                                                    setError(prevState => ({ ...prevState, email: false, message: "" }));
                                                }}
                                                className="bg-[#F1F8E8] rounded-lg block text-gray-900 w-full p-4 focus:outline-none"
                                            />
                                        </div>
                                        <p className=" font-medium text-black">Phone Number</p>
                                        <div className="relative mb-6">
                                            <input
                                                disabled={disabled}
                                                error={error.phone}
                                                id="phone-input"
                                                value={formInput.phone}
                                                placeholder='Enter your phone number'
                                                onChange={(e) => handlePhoneInputChange(e)}
                                                className="bg-[#F1F8E8] rounded-lg block text-gray-900 w-full p-4 focus:outline-none"
                                            />
                                        </div>
                                        <p className="font-medium text-black">Enter Message</p>
                                        <div className="relative mb-6">
                                            <textarea
                                                disabled={disabled}
                                                error={error.msg}
                                                id="message-input"
                                                value={formInput.message}
                                                placeholder='Write your message here...'
                                                onChange={(e) => {
                                                    setFormInput(prevState => ({ ...prevState, message: e.target.value }));
                                                    setError(prevState => ({ ...prevState, msg: false, message: "" }));
                                                }}
                                                className="bg-[#F1F8E8] rounded-lg block text-gray-900 w-full p-4 focus:outline-none"
                                            />
                                        </div>
                                        <button onClick={submissionHandler} className="text-white bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] rounded-full md:px-10 max-md:px-8 md:py-4 max-md:py-3 inline-flex items-center duration-[400ms,700ms]">Submit Message</button>
                                    </form>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ End Contact Form Section /} */}
            <div className="contact-detail-section w-full bg-[#F1F8E8] py-20">
                <div className="container mx-auto px-2">
                    <div className="flex lg:gap-14 max-lg:gap-5 max-md:flex-col">
                        <div className="md:w-1/3 relative max-md:mb-5">
                            <div className="detail-content-box bg-[#fff] rounded-[20px] shadow-[0_2px_20px_10px_rgba(0,0,0,0.1)] h-[100%] py-[60px] flex justify-center before:content-[' '] before:absolute before:left-0 before:right-0 before:w-[100%] before:top-0 before:h-[310px] before:rounded-[20px]">
                                <div className="text-center z-10">
                                    <div className="detial-icon-info mb-[20px]">
                                        <div className="w-20 h-20 rounded-full bg-[#F1F8E8] mx-auto text-center p-5 cursor-pointer" onClick={callPhoneNumber}>
                                            <MdPhone className='h-10 w-10 flex items-center justify-center text-[#0B7132]' />
                                        </div>
                                    </div>
                                    <h4 className="text-center mb-2">Phone</h4>
                                    <p className="text-center cursor-pointer" onClick={callPhoneNumber}>{phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 relative max-md:mb-5">
                            <div className="detail-content-box bg-[#fff] rounded-[20px] shadow-[0_2px_20px_10px_rgba(0,0,0,0.1)] h-[100%] py-[60px] flex justify-center before:content-[' '] before:absolute before:left-0 before:right-0 before:w-[100%] before:top-0 before:h-[310px] before:rounded-[20px]">
                                <div className="text-center z-10">
                                    <div className="detial-icon-info mb-[20px]">
                                        <div className="w-20 h-20 rounded-full bg-[#F1F8E8] mx-auto text-center p-5 cursor-pointer" onClick={openMail}>
                                            <FaRegEnvelope className='h-10 w-10 flex items-center justify-center text-[#0B7132]' />
                                        </div>
                                    </div>
                                    <h4 className="text-center mb-2">Email</h4>
                                    <p className="text-center cursor-pointer" onClick={openMail}>{email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 relative">
                            <div className="detail-content-box bg-[#fff] rounded-[20px] shadow-[0_2px_20px_10px_rgba(0,0,0,0.1)] h-[100%] py-[60px] flex justify-center before:content-[' '] before:absolute before:left-0 before:right-0 before:w-[100%] before:top-0 before:h-[310px] before:rounded-[20px]">
                                <div className="text-center z-10">
                                    <div className="detial-icon-info mb-[20px]">
                                        <div className="w-20 h-20 rounded-full bg-[#F1F8E8] mx-auto text-center p-5">
                                            <MdLocationPin className='h-10 w-10 flex items-center justify-center text-[#0B7132]' />
                                        </div>
                                    </div>
                                    <h4 className="text-center mb-2">Location</h4>
                                    <p className="text-center px-2 cursor-pointer" onClick={handleClick}>Hall 3, IT Wing, B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ End Contact Form Section /} */}
            <iframe className="md:h-[500px] max-md:h-[300px]" title="Embedded Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.1821211489823!2d76.6942457!3d30.713280100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef51d3a3d00b%3A0x92c78e59f10fa179!2sMy%20EV%20Point%20Charging%20Station!5e0!3m2!1sen!2sin!4v1705385959687!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}
export default Contact;