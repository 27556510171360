import React from "react";
import Header from "../components/header/Header";
import Banner from "../components/home/Banner";
import AboutUs from "../components/home/AboutUs";
import Features from "../components/home/Features";
import Strategy from "../components/home/Strategy";
import Charges from "../components/home/Charges";
import Footer from "../components/footer/Footer";
import Testimonials from "../components/testimonials/Testimonials";
import ContactInfo from "../components/contact_info/ContactInfo";
import Ourgallery from "../components/home/OurGallery";
import Ournewsletter from "../common/OurNewsletter";
import Blogsection from "../common/BlogSection";
import SEO from "../common/SEO";
import Content from "../components/home/content";

const Home = () => {
    return (
        <>
            <SEO title="Best EV charging station service providers: MyEV Point"
                description="Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs." 
                keywords="ev charging stations, ev charging stations near me, ev charging station providers, ev charging service providers, electric charging stations, electric vehicle charging, ev charging stations cost, electric vehicle charging station, electric vehicle charging station cost, public ev charging stations, electric cars charging stations, electric vehicle charging cost, electric vehicle charging near me, fast charging stations near me, electric vehicle chargers near me, ev fast charging stations, ev car charging station, ev charging services, electric vehicle station near me, electric car stations"
                schema={`
                    {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "myevpoint",
                    "url": "https://www.myevpoint.in/",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://www.myevpoint.in/?search={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                    }
                `} 
                organization={`
                    {         
                    "@context": "https://schema.org/",         
                    "@type": "Organization",         
                    "@id": "https://www.myevpoint.in/#Organization",         
                    "url": "https://www.myevpoint.in/",         
                    "legalName": "My Ev Point",         
                    "name": "myevpoint",         
                    "description": "Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs.",         
                    "logo": "https://www.myevpoint.in/static/media/myEV_logo.9baa1dd0c29762718e22.webp",         
                    "telephone": "+91 9592-595975",         
                    "email": "mailto:info@myevpoint.in",         
                    "address": {             
                        "@type": "PostalAddress",             
                        "streetAddress": "B-70, Phase 7, Sector 74",             
                        "addressLocality": "SAS Nagar",             
                        "addressRegion": "Punjab",             
                        "addressCountry": "IN",             
                        "postalCode": "160055"        
                    },         
                    "sameAs": [             
                        "https://www.facebook.com/myevpoint",          
                        "https://www.youtube.com/@MyEVPoint",             
                        "https://www.instagram.com/myevpoint/",             
                        "https://www.linkedin.com/company/my-ev-point/",           
                        "https://x.com/myevpoint"       
                    ] 
                    } 
                `}
                business={`
                    {         
                    "@context": "https://schema.org/",         
                    "@type": "LocalBusiness",         
                    "@id": "https://www.myevpoint.in/#LocalBusiness",         
                    "url": "https://www.myevpoint.in/",          
                    "name": "My Ev Point",
                    "description": "Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs.",
                    "logo": "https://www.myevpoint.in/static/media/myEV_logo.9baa1dd0c29762718e22.webp",
                    "telephone": "+91 9592-595975",
                    "email": "info@myevpoint.in",
                    "address": {             
                        "@type": "PostalAddress",             
                        "streetAddress": "Hall 3, IT Wing, B-70, beside Falcon Enterprises, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar",             
                        "addressLocality": "Mohali",             
                        "addressRegion": "PB",             
                        "addressCountry": "India",             
                        "postalCode": "160055"        
                    }
                    } 
                `}
            />
            <Header />
            <Banner />
            <AboutUs />
            <Features />
            <Strategy />
            <Ourgallery />
            <Ournewsletter />
            <Charges />
            <Testimonials />
            <Blogsection />
            <Content />
            <ContactInfo />
            <Footer />
        </>
    )
};
export default Home;