import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import ServicesList from '../components/services/ServicesList'
import Strategy from '../components/services/Strategy'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/OurServiceAC&DCCurrent.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'

const Services = () => {
    const location = useLocation();
    
    useEffect(() => {
        // Check if the URL has a trailing slash
        if (location.pathname.endsWith("/") && location.pathname !== "/") {
            const newPath = location.pathname.replace(/\/$/, "");
            // Modify history using window.history.replaceState() instead of navigate()
            window.history.replaceState(null, "", newPath);
      }
    }, [location.pathname]);

    return (
        <>
            <SEO title='AC and DC EV Charging Stations | MyEVPoint' description='AC and DC charging Stations for electric vehicles. Learn about different charging stations and methods to power your EV efficiently.' 
            keywords='ac and dc charging station, ac and dc charging of ev, ac and dc charging in electric vehicle, ac and dc car charging, ac and dc ev charging, electric car charging ac or dc, electric vehicle charging ac or dc'/>
            <Header />
            <InnerPageBanner title='Our Services' maxWidth='472px' innerPageBanner={aboutBanner} />
            <ServicesList />
            <Strategy />
            <ContactInfo />
            <Footer />
        </>
    )
}

export default Services;
