import React from "react";

const Oursolutions = () => {
    return(
        <>
             <p>Our Solutions</p>
        </>
    )

}
export default Oursolutions;