import React from 'react';

const Videos = () => {
  return (
    <>
      {/* Gallery Page */}
      <div className="gallery-section md:py-20 max-md:py-[50px] relative">
        <div className="container mx-auto px-2">
          <div className="grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 md:gap-[30px] max-md:gap-[15px] pb-2 mx-auto">
            <div className='relative p-[28%] w-full overflow-hidden'>
              <iframe className='absolute top-0 left-0 w-full h-full object-center object-cover'  src="https://www.youtube.com/embed/QraLIsumt0U?si=6J2_jt59XStVQLFa" title="YouTube Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='relative p-[28%] w-full overflow-hidden'>
              <iframe className='absolute top-0 left-0 w-full h-full object-center object-cover' src="https://www.youtube.com/embed/a0mDIPbZtNo?si=g9-SLkEi8F7jb_2i" title="YouTube Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;

