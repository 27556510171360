import { useState } from 'react';
import evchargingnews from '../assets/Images/ev_charging23.webp'
import axios from 'axios';
import { validateEmail } from '../utils/formFunctions';
const Ournewsletter = () => {
  const [email, setEmail] = useState("")
  const [successMsg, setSuccessMsg] = useState(false)
  const [error, setError] = useState({
    message: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(prevState => ({ ...prevState, message: "" }));
    if (!email.trim()) {
      setError(prevState => ({ ...prevState, message: "Please enter your email" }));
    }else if (!validateEmail(email)) {
      setError(prevState => ({ ...prevState, message: "Invalid email format!" }));
    } else {
      try {
         await axios.post(`${process.env.REACT_APP_URL}/user/subscribeNewsLetter`, {
          email: email,
        });
        setEmail('')
        setSuccessMsg(true)
        setTimeout(() => {
          setSuccessMsg(false)
        }, 2000);
      } catch (error) {
      }
    }
  };

  return (
    <>
      {/* Our Newsletter Section */}
      <div className="newsletter-section md:mt-14 relative bg-[#0B7132]">
        <div className="container mx-auto px-2">
          <div className="flex max-md:flex-col">
            <div className="md:w-1/2 relative md:py-[40px] max-md:pt-[40px] max-md:pb-[20px]">
              <h2 className="text-[#FFF]">Our Newsletter</h2>
              <p className="text-[#FFF]">Sign Up To Get Exclusive Offers And News From Our Favorite Brands !</p>
              {successMsg ?
                <div className="newsletter-form-info mt-8">
                  <form className="flex md:max-w-[550px] ">
                    <div className="w-full relative max-lg:flex max-lg:flex-col bg-black rounded-full">
                      <div className="bg-[#000] text-white rounded-full block w-full p-4 max-lg:mb-4 ">
                        <p className="text-[#FFF] text-center w-full">Thank you for subscribing our Newsletter.</p>
                      </div>
                    </div>
                  </form>
                </div>
                :
                <div className="newsletter-form-info mt-8">
                  <form onSubmit={handleSubmit} className="flex md:max-w-[550px]">
                    <div className="w-full relative max-lg:flex max-lg:flex-col">
                      <input value={email} onChange={(e) => {
                          setEmail(e.target.value)
                          setError(prevState => ({ ...prevState, message: "" }));
                        }} className="focus:outline-none bg-[#fff] text-gray-900 rounded-full block w-full p-4 lg:pe-[180px] max-lg:mb-4"
                        placeholder="Email Address*" />
                      <button className="lg:absolute lg:right-0 lg:top-0 lg:ml-2 rounded-full bg-[#000] text-[#fff] hover:bg-[#F1F8E8] hover:border hover:text-[#000] border block py-[15.2px] px-7 duration-[400ms,700ms]">Subscribe Now</button>
                    </div>
                  </form>
                  <p className={`text-[14px] ml-4 mt-2 text-left text-[#e50914] font-normal ${error.message ? 'block' : 'hidden'}`}>{error.message}</p>
                </div>
              }
            </div>
            <div className="md:w-1/2 relative">
              <div>
                <img className="md:absolute md:left-0 md:right-0 md:bottom-0 w-full md:max-w-[304px] max-md:max-w-[150px] m-auto md:mt-[-74px]" src={evchargingnews} alt="Electric Vehicle Charger" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Our Newsletter Section */}
    </>
  );
};
export default Ournewsletter;