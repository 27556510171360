import React from 'react'
import { Link } from 'react-router-dom';
import ImageIcons from '../../common/ImageIcons'
import { GiFastArrow } from "react-icons/gi";
import { windowScroll } from '../../helpers/ScrollToTop';

const AboutUs = () => {
    return (
        <>
            {/* About Us Section */}
            <div className="about-us-section md:pb-[100px] max-md:pb-[50px] md:pt-14 px-3">
                <div className="about-us-info mb-7">
                    <div className="container m-auto max-md:!px-0">
                        <div className='flex lg:flex-nowrap flex-wrap'>
                            <div className='lg:w-[50%] w-full xl:pe-[50px] lg:pe-[40px] !pb-0 relative '>
                                <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                                    <h2 className="mb-5">Electric Vehicle Charging Stations with My EV Point</h2>
                                    <p className='mb-3'>In today’s world, the need for sustainable transportation is growing more than ever before because the environment has degraded up to such an extent that if steps are not taken right away, the repercussions would be horrendous for us and our upcoming generation. As the main reasons for increased pollution are our modes of transportation, electric vehicles prove to be life savers in such circumstances by not only saving our environment but also the fossil fuels. People have also become environmentally conscious and are showing a great preference towards electric vehicles.</p>
                                    <p className='mb-14'>Although the adoption of these vehicles is growing at a faster pace, to truly take it off, there is a huge requirement of EV charging stations and robust EV charging infrastructure because to ensure seamless user experience, electric charging stations play a very crucial role and here, MyEV points has come up with the unique solutions for you. We, at myEV point, offer robust electric vehicle charging station infrastructure for both home installation as well as public place installation.</p>
                                </div>
                            </div>
                            <div className='md:min-h-[500px] sm:min-h-[400px] md:me-[25px] me-[15px] min-h-[330px] relative lg:w-[50%] w-full content-[&quot;&quot;] after:absolute md:after:top-[-25px] after:top-[-15px] md:after:right-[-25px] after:right-[-15px] after:w-[calc(100%_-_80px)] after:z-[-1] after:rounded-[20px] after:bg-[#0B7132] md:after:h-[calc(100%_+_50px)] after:h-[calc(100%_+_30px)]'>
                                <img className="object-cover object-right h-full w-full absolute rounded-[20px]" src={ImageIcons.aboutUs_car} alt="Electric Vehicle Charging Stations" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 bg-[#F1F8E8] justify-between items-center">
                <div className="about-us-info">
                    <div className="container m-auto max-md:!px-0 bg-no-repeat paralex-bg" style={{ backgroundImage: `url(${ImageIcons.EVCharging})` }}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-[30px]'>
                            <div className='md:pt-[100px] max-md:pt-[50px] md:pb-10'>
                                <h3 className="md:mb-10 max-md:mb-7">We Provide</h3>
                                <div className='flex group items-center mb-[30px]'>
                                    <div className='flex justify-center items-center sm:h-[55px] sm:min-w-[55px] h-[50px] min-w-[50px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#0B7132] text-[55px] text-[#0B7132] group-hover:text-[#fff]'>
                                        <span className='sm:text-[30px] text-[25px] -rotate-45'>1</span>
                                    </div>
                                    <div>
                                        <h5 className='pl-[20px] text-left'>State-of-the-art charging infrastructure.</h5>
                                    </div>
                                </div>
                                <div className='flex group items-center mb-[30px]'>
                                    <div className='flex justify-center items-center sm:h-[55px] sm:min-w-[55px] h-[50px] min-w-[50px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#0B7132] text-[55px] text-[#0B7132] group-hover:text-[#fff]'>
                                        <span className='sm:text-[30px] text-[25px] -rotate-45'>2</span>
                                    </div>
                                    <div>
                                        <h5 className='pl-[20px] text-left'>Fast and reliable charging.</h5>
                                    </div>
                                </div>
                                <div className='flex group items-center mb-[30px]'>
                                    <div className='flex justify-center items-center sm:h-[55px] sm:min-w-[55px] h-[50px] min-w-[50px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#0B7132] text-[55px] text-[#0B7132] group-hover:text-[#fff]'>
                                        <span className='sm:text-[30px] text-[25px] -rotate-45'>3</span>
                                    </div>
                                    <div>
                                        <h5 className='pl-[20px] text-left'>High-powered charging capability.</h5>
                                    </div>
                                </div>
                                <div className='flex group items-center mb-[30px]'>
                                    <div className='flex justify-center items-center sm:h-[55px] sm:min-w-[55px] h-[50px] min-w-[50px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#0B7132] text-[55px] text-[#0B7132] group-hover:text-[#fff]'>
                                        <span className='sm:text-[30px] text-[25px] -rotate-45'>4</span>
                                    </div>
                                    <div>
                                        <h5 className='pl-[20px] text-left'>Affordable price range.</h5>
                                    </div>
                                </div>
                                <div className='flex group items-center mb-[30px]'>
                                    <div className='flex justify-center items-center sm:h-[55px] sm:min-w-[55px] h-[50px] min-w-[50px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#0B7132] text-[55px] text-[#0B7132] group-hover:text-[#fff]'>
                                        <span className='sm:text-[30px] text-[25px] -rotate-45'>5</span>
                                    </div>
                                    <div>
                                        <h5 className='pl-[20px] text-left'>Charging infrastructure compatible with different makes and models</h5>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* <img src={ImageIcons.EVCharging} alt="EVCharging" className='w-full max-w-[350px] m-auto' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 bg-[#E7F0F7] justify-between items-center" >
                <div className="about-us-info">
                    <div className="container m-auto max-md:!px-0 bg-no-repeat paralex-bg" style={{ backgroundImage: `url(${ImageIcons.EVCharging})` }}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-[30px]'>
                            <div className="md:pt-14 max-md:pt-10 md:pb-[100px] max-md:pb-[30px]">
                                <h3 className="mb-3">Let’s Discuss In Detail</h3>
                                <p className='mb-5'>MyEV point not only offers charging stations for residential use but for commercial purposes also. If you are looking for residential installation you can take advantage of our Level  1 and Level 2 AC charging. </p>
                                <div>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>Level 1 charging require 120V, so it is easy to plug in the car in a standard household outlet</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>It is the slowest form of charging.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>If you need to cover short distances on a regular basis, this charging level is suitable for you.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[30px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>Level 2 electric car charging stations require 200 to 240 V supply and for this special equipment needs to be installed at home for which, we, as EV charging service providers will provide professional support.</p>
                                        </div>
                                    </div>
                                </div>
                                <h5 className='mb-4'>If you are looking for commercial EV charging station installation, take advantage of our Level 3 DC charging infrastructure.</h5>
                                <div className='mb-5'>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>Voltage requirements are 400-1000V and thus, not suitable for home installation.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>Fastest form of charging.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start mb-[10px]">
                                        <div>
                                            <GiFastArrow className='-rotate-45 mt-1' />
                                        </div>
                                        <div className='lg:pl-[15px] max-lg:pl-[15px] text-left'>
                                            <p>Provides enhanced user experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <p>Our technology is industry leading and with us, transition to electric becomes quite easy. We are always committed to provide an extraordinary experience of charging and charging infrastructure to our customers. Our support team is always at your service.</p>
                            </div>
                            <div>
                                {/* <img src={ImageIcons.EVCharging} alt="EVCharging" className='w-full max-w-[350px] m-auto' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="overview-services-section md:pt-14 max-md:pt-10 md:pb-[100px] max-md:pb-[50px] px-3">
                <div className="overview-services-info">
                    <div className="container m-auto max-md:!px-0">
                        <h2 className="mb-5">Overview of Our Services</h2>
                        <p className='mb-10'>Get a chance to revolutionise your charging experience with myEV points’ charging station services. We provide state-of-the-art AC charging stations, DC charging stations  as well as EV charging station applications so that we can deliver innovative solutions to make your EV ownership smart, convenient and relaxing. Join us today and take your first step towards a cleaner and greener future.</p>
                        <div className='flex md:gap-20 max-md:flex-col'>
                            <div className='md:w-1/3'>
                                <div className="max-md:mb-[30px]">
                                    <img className="sm:w-[75px] max-sm:w-[50px] mb-4" src={ImageIcons.icon1} alt="Electric Vehicle AC Charging Station" />
                                    <div className='text-left'>
                                        <h4 className='mb-[10px] text-[#016C80] font-semibold'>Electric Vehicle AC Charging Station</h4>
                                        <p>Our designs are user-friendly and installation options are quite flexible. This makes our <Link to='/services/ac-charging-station' onClick={windowScroll} className='font-medium	'>AC charging stations</Link> a convenient approach for having an AC charger for EV cars at home. MyEV points’ electric vehicle AC chargers are also suitable for public places, as they offer smart charging at minimal prices. We ensure that our AC charging for electric vehicles contributes to a cleaner and sustainable future.</p>

                                    </div>
                                </div>
                            </div>
                            <div className='md:w-1/3'>
                                <div className="max-md:mb-[30px]">
                                    <img className="sm:w-[75px] max-sm:w-[50px] mb-4" src={ImageIcons.icon2} alt="Electric Vehicle DC Charging Station" />
                                    <div className='text-left'>
                                        <h4 className='mb-[10px] text-[#016C80] font-semibold'>Electric Vehicle DC Charging Station</h4>
                                        <p>Our <Link to='/services/dc-charging-station' onClick={windowScroll} className='font-medium'>DC charging stations</Link> are made for those who want a quick charging. MyEV points’ DC fast charging stations ensure quick power up and efficiency while on the go. You are provided with advanced safety features, protection from overcurrent and a fault detection system, in order to enhance your charging experience you charge your vehicle at a DC fast charger near you.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='md:w-1/3'>
                                <div className="max-md:md:mb-[40px]">
                                    <img className="sm:w-[75px] max-sm:w-[50px] mb-4" src={ImageIcons.icon3} alt="Reliability" />
                                    <div className='text-left'>
                                        <h4 className='mb-[10px] text-[#016C80] font-semibold'>Electric Vehicle App Solutions</h4>
                                        <p>Enhance every aspect of EV ownership with EV car charging station app, as with our <Link to='/ev-charging-app' onClick={windowScroll} className='font-medium'>electric car charging station app</Link>, you can easily optimise the schedule for charging your EV. Find your nearby charging stations and start charging your vehicle seamlessly with our best EV charging app in India. Not only this, you can also monitor the performance of your electric vehicle and keep a track of energy usage with our application.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Us Section */}
        </>
    )
}

export default AboutUs
