import React, { useEffect, useState } from 'react'
import bgcoverimh1 from "../../assets/Images/bg-cover-imh1.webp";
import chaticon from "../../assets/Images/chat-icon.webp";
import pdficon1 from "../../assets/Images/pdf-icon-1.webp";
import { Link } from 'react-router-dom';
import brocher from "../../assets/utils/myEV_borcher.pdf"
import { useLocation } from "react-router-dom";
import '../../App.css'
import './blog.css'
import axios from 'axios';
import { windowScrollTop } from '../../helpers/ScrollToTop';

const SingleBlogPage = ({ blogDetails }) => {
    const location = useLocation();
    const { pathname } = location;
    const phoneNumber = '+91 95925 95975';
    const phoneLink = `tel:${phoneNumber}`;
    const callPhoneNumber = () => {
        window.location.href = phoneLink;
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };
    const [allBlogs, setAllBlogs] = useState([])
    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
        const filteredData = response.data.data.filter(item => item.isActive === true);
        const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
        const latest6Blogs = sortedBlogs.slice(0, 6);
        setAllBlogs(latest6Blogs)
    }
    useEffect(() => {
        fetchBlogs()
    }, [])
    const dateObject = new Date(blogDetails?.publishedAt);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });

    return (
        <>
            <div className="relative text-center sm:pt-[35%] pt-[40%] w-full">
                <img src={blogDetails?.image} effect="blur" alt={blogDetails?.alttag} title={blogDetails?.imagetitle} className='absolute top-0 left-0 w-full h-full object-center object-cover' />
            </div>
            <div className='single-blog-page w-full md:py-10 max-md:py-10'>
                <div className='container mx-auto'>
                    <div className='single-blog-info flex gap-10 max-md:flex-col'>
                        {/* Left Side Bar Info */}
                        <div className='md:w-3/4 relative mb-5 mt-10 max-md:order-1'>
                            {/* Single Blog Content */}
                            <div className='single-service-right-sidebar'>
                                <h1 className='mb-4'>{blogDetails?.title}</h1>
                                <p className='mb-4' dangerouslySetInnerHTML={{ __html: blogDetails?.description }} />
                            </div>
                        </div>
                        {/* Right Side Bar Info */}
                        <div className='md:w-1/4 relative mb-5 mt-10 max-md:order-2'>
                            <div className='single-blog-left-sidebar'>
                                {/* Our Recent Post Sidebar Links */}
                                <div className='box-shodow-info xl:px-10 xl:py-10 max-xl:px-5 max-xl:py-5 rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='title-sidebar mb-5'>
                                        <h4>Recent Post</h4>
                                        <hr className="green-hr-line" />
                                    </div>
                                    {allBlogs.map((item) => {
                                        return (
                                            <div className={`${pathname === `/blogs/${item?.handle}` && "bg-[#0B7132] text-white rounded-md"} blog-links-info `} key={item.id}>
                                                <ul>
                                                    <li>
                                                        <Link
                                                            style={{
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden',
                                                                WebkitLineClamp: 1,
                                                                lineHeight: 2
                                                            }}
                                                            to={`/blogs/${item?.handle}`}
                                                            onClick={windowScrollTop}
                                                            className='flex px-4 py-2 hover:bg-[#0B7132] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]'>
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* Contact Info Sidebar */}
                                <div className='box-shodow-info rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='contact-sidebar-info'>
                                        <div className='contact-img-gril'>
                                            <img src={bgcoverimh1} alt='blog' className='w-full' />
                                        </div>
                                        <div className='contact-txt-info relative text-center xl:px-10 xl:pb-10 max-xl:px-5 max-xl:pb-5 mt-[-80px]'>
                                            <div className='icon-contact-info'>
                                                <img src={chaticon} alt='chaticon' className='w-15 h-15 m-auto' />
                                            </div>
                                            <h4 className='mb-3 mt-4 text-white font-medium'>Do you need any help?</h4>
                                            <p onClick={callPhoneNumber} className='font-medium text-white cursor-pointer'>{phoneNumber}</p>
                                            <p onClick={openMail} className='font-medium text-white cursor-pointer'>{email}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Download Info Sidebar */}
                                <div className='box-shodow-info xl:px-9 xl:py-10 max-xl:px-5 max-xl:py-5 rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                                    <div className='title-sidebar mb-5 pb-5'>
                                        <h4>Download</h4>
                                        <hr className="green-hr-line" />
                                    </div>
                                    <div className='flex flex-row'>
                                        <img src={pdficon1} alt='pdficon1' className='w-12 h-12 mr-2' />
                                        <div className='download-txt-info'>
                                            <h5 className='text-lg font-medium'>Download our Brochure</h5>
                                            <p><Link
                                                className="flex items-center gap-2 cursor-pointer text-[#0B7132] font-medium "
                                                to={brocher}
                                                download="myEV_Point"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >Download</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mx-auto !max-w-[1200px] flex justify-between items-center mb-[20px]'>
                <div className='mb-0 font-medium text-[14px]'><span className="font-bold text-[14px]">Author:</span> {blogDetails?.author} </div>
                <div className='mb-0 font-medium text-[14px]'><span className="font-bold text-[14px]">Published At:</span> {formattedDate}</div>
            </div>
        </>
    );
};
export default SingleBlogPage;
