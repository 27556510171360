import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@mui/material/Switch";
import axios from "axios";

const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '70%',
    maxHeight: '90%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    py: 2,
    px: 4
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.white,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const AllGallery = ({ trigger }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState('')
    const handleClose = () => setOpen(false);
    const handleView = (item) => {
        setOpen(true);
        setDetail(item)
    };
    const handleSwitchChange = async (id) => {
        try {
          await axios.get(`${process.env.REACT_APP_URL}/user/editGallery/${id}`);
        } 
        catch (error) {
        }
        fetchData();
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/user/getGallery`);
            setData(response?.data?.data);
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchData();
    }, [trigger]);

    return (
        <>
            <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px] mt-16">
                <TableContainer sx={{ maxWidth: 1000, minWidth: 1000 }}>
                    <Table aria-label="sticky table" className="w-150">
                        <TableHead >
                            <TableRow >
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>No.</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                    <strong>Title</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 300, minWidth: 300 }}>
                                    <strong>Description</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>View</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>Status</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>Edit</strong>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {data.map((data, index) => {
                            return (
                                <TableBody>
                                    <StyledTableRow sx={{ borderBottomWidth: 1 }}>
                                        <StyledTableCell
                                            sx={{ maxWidth: 50, minWidth: 50 }}
                                        >
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.title}</StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.image}</StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                            <IconButton aria-label="edit" size="large" color="info" onClick={() => handleView(data)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <IconButton
                                            style={{ color: data?.isActive ? "#0B7132" : "#e50914" }}
                                            aria-label="delete"
                                            size="medium">
                                        </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch
                                                    checked={data.isActive}
                                                    onChange={() => handleSwitchChange(data?._id)}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                />}
                                                />
                                            </FormGroup>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </TableContainer>
            </div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description">
                <div className='dashboard'>
                    <Box sx={style}>
                        <main className="mt-2">
                            <div className="mb-4 md:mb-0 w-full mx-auto relative" onClick={handleClose}>
                                <div className="text-right">
                                    <IconButton aria-label="edit" color="inherit"  >
                                        <CloseIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </div>
                                <div className="px-4 lg:px-0">
                                    <h2 className="text-4xl font-semibold text-gray-800 leading-tight text-center">
                                        {detail?.title}
                                    </h2>
                                </div>
                                {detail?.image &&
                                    <img src={detail?.image} className="h-80 object-cover lg:rounded mx-auto mt-5" alt="Ev Charging Machine" />
                                }
                            </div>
                            <div className="flex flex-col lg:flex-row lg:space-x-12">
                                <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-full">
                                    <p className="pb-6">{detail?.description}</p>
                                </div>
                            </div>
                        </main>
                    </Box>
                </div>
            </Modal>
        </>
    );
};

export default AllGallery;