import React from "react";
import Login from "../components/admin/login/Login";

const AdminLogin = () => {
    return (
        <>
            <Login/>
        </>
    )
};

export default AdminLogin;